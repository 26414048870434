import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import Networks from "../../network";
import "./index.scss";
import {
  modelRevenueDetail,
  modelLineChart,
  KeyCategories
} from "./viewModel";
import { useParams, useLocation, useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chart from "react-apexcharts";
import moment from "moment";
import utils from "../../utils";
import DetailError from "../../components/DetailError";
import TableInfiniteKitchen from './component-parts/TableInfiniteKitchen'

const InfiniteKitchenDetail = props => {
  const { businessId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const yearMonth = moment(
    `${query.get("year")} ${query.get("month")}`,
    "YYYY MM",
  )
    .startOf("month")
    .toDate();

  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(null);
  const [lineChart, setLineChart] = useState(null);
  const [date, setDate] = useState(yearMonth);
  const [businessName, setBusinessName] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    let subscribe = { mounted: true };
    setDate(yearMonth);
    _fetchData(
      moment(yearMonth).format("MM"),
      moment(yearMonth).format("YYYY"),
      subscribe,
    );
    return () => {
      subscribe.mounted = false;
    };
  }, [query.get("year"), query.get("month")]);

  const _onFetchError = subscribe => {
    if (!subscribe.mounted) return;
    setLoading(false);
    setBusiness(null);
    setLineChart(null);
  };

  const _fetchData = (month, year, subscribe) => {
    setLoading(true);
    const type = query.get("type");
    const yearMonthMode = `?year_month=${year}-${month}&mode=year-month`;
    const yearMode = `?year=${year}&mode=year`;
    Promise.all(
      type === "group"
        ? [
            Networks.getGroupRevenue(businessId, yearMonthMode),
            Networks.getGroupRevenue(businessId, yearMode),
          ]
        : [
            Networks.getRevenue(businessId, yearMonthMode),
            Networks.getRevenue(businessId, yearMode)
          ],
    )
      .then(res => {
        if (!subscribe.mounted) return;
        if (!!utils.anyApiError(res)) {
          const _error = utils.anyApiError(res);
          setError(
            "ดึงข้อมูลรายได้ไม่สำเร็จ code: " +
              (_error?.statusCode || "Unknown"),
          );
        } else {
          setError(null);
        }

        if (!!res[0].data && !!res[1].data) {
          let businessName = "";
          let expense = null;
          let expenseGraph = null;
          let daily = null;
          if (type === "group") {
            businessName = res[0].data.name || "";
            expense = { revenue: res[0].data.summary };
            expenseGraph = res[1].data.summaries;
          } else {
            businessName = res[0].data.name || "";
            expense = res[0].data;
            expenseGraph = res[1].data.revenues;
          }
          setBusinessName(businessName);
          setBusiness(modelRevenueDetail(expense));
          setLineChart(modelLineChart(expenseGraph));
        } else {
          setBusiness(null);
          setLineChart(null);
        }
        setLoading(false);
      })
      .catch(() => _onFetchError(subscribe));
  };

  const _handleChange = e => {
    query.set("month", e.format("MM"));
    query.set("year", e.format("YYYY"));
    const queries = [];
    query.forEach((v, k) => queries.push(`${k}=${v}`));
    history.replace(`${location.pathname}?${queries.join("&")}`);
  };

  const _prevMonth = () => {
    _handleChange(moment(date).add(-1, "months"));
  };

  const _nextMonth = () => {
    _handleChange(moment(date).add(1, "months"));
  };

  const _renderDatePicker = () => {
    return (
      <div className="datepicker-container">
        <div className="btn-prev-next-month" onClick={_prevMonth}>
          <FontAwesomeIcon
            style={{ width: 24, height: 24, color: "#fff" }}
            icon="chevron-left"
          />
        </div>
        <div style={{ color: "#ffffff" }} className="date-yearmonth">
          {moment(date).format("MMM YYYY")}
        </div>
        <div className="btn-prev-next-month" onClick={_nextMonth}>
          <FontAwesomeIcon
            style={{ width: 24, height: 24, color: "#fff" }}
            icon="chevron-right"
          />
        </div>
      </div>
    );
  };

  const _renderHeader = () => {
    return (
      <div className="header">
        <div className="business-label">Monthly Report:</div>
        <div className="business-name mb-1">{businessName}</div>
        {_renderDatePicker()}
      </div>
    );
  };

  const _renderLineChart = () => {
    if ((loading && !lineChart) || !!error) return null;

    return (
      <Chart
        options={{
          noData: {
            text: "ไม่มีข้อมูล",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#115fa7",
              fontSize: "1.5rem",
              fontFamily: undefined,
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: 2,
            curve: "smooth",
          },
          labels: lineChart?.dates || [],
          xaxis: {
            type: "datetime",
          },
          yaxis: {
            title: {
              text: "(ล้าน)",
            },
          },
          tooltip: {
            enabled: false,
          },
        }}
        series={lineChart?.series || []}
        width={Math.min(window.screen.width, 512)}
        type="line"
      />
    );
  };

  const _renderTable = () => {
    if (!business || query.get("type") !== "business" || loading || !!error) return null;
    return (<TableInfiniteKitchen version="new"/>);
  };

  const _renderRevenueDetail = () => {
    if (loading || !business || !!error) return null;
    return (
      <div className="revenue-detail mt-3">
        <div className="group">
          <div className="label-group">
            <span className="label-name">{business.revenue.name}</span>
            <span className="label-unit">{business.revenue.unit}</span>
          </div>
          <div className="list-label-data">
            {business.expense_categories
              ?.filter(v => v.type === KeyCategories.revenue)
              ?.map((v, i) => (
                <div key={i} className="label-data">
                  <span className="label-name-category">
                    {v.expense_category.name}
                  </span>
                  <span className="label-unit">{v.unit}</span>
                </div>
              ))}
          </div>
        </div>
        <div className="group">
          <div className="label-group">
            <span className="label-name">{business.variable_cost.name}</span>
            <span className="label-unit">{business.variable_cost.unit}</span>
          </div>
          <div className="list-label-data">
            {business.expense_categories
              ?.filter(v => v.type === KeyCategories.cost)
              ?.map((v, i) => (
                <div key={i} className="label-data">
                  <span className="label-name-category">
                    {v.expense_category.name}
                  </span>
                  <span className="label-unit">{v.unit}</span>
                </div>
              ))}
          </div>
        </div>
        <div className="group line">
          <div className="label-group">
            <span className="label-name">{business.gross_profit.name}</span>
            <span
              className="label-unit"
              style={{
                color: utils.getNumberColor(
                  business.gross_profit.unit,
                  "#115fa7",
                ),
              }}>
              {business.gross_profit.unit}
            </span>
          </div>
        </div>
        <div className="label-group">
          <span className="label-name">{business.opex_support.name}</span>
          <span className="label-unit">{business.opex_support.unit}</span>
        </div>
        <div className="group">
          <div className="group">
            <div className="label-group">
              <span className="label-name">{business.opex_business.name}</span>
              <span className="label-unit">{business.opex_business.unit}</span>
            </div>
          </div>
          <div className="list-label-data">
            {business.expense_categories
              ?.filter(v => v.type === "opex")
              ?.map((v, i) => (
                <div key={i} className="label-data">
                  <span className="label-name-category">
                    {v.expense_category.name}
                  </span>
                  <span className="label-unit">{v.unit}</span>
                </div>
              ))}
          </div>
        </div>

        <div className="group line">
          <div className="label-group">
            <span className="label-name">{business.opex.name}</span>
            <span className="label-unit">{business.opex.unit}</span>
          </div>
        </div>

        <div className="group">
          <div className="label-group">
            <span className="label-name">{business.ebitda.name}</span>
            <span
              className="label-unit"
              style={{
                color: utils.getNumberColor(business.ebitda.unit, "#115fa7"),
              }}>
              {business.ebitda.unit}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="page-container revenue-page detail">
      {_renderHeader()}
      <div
        style={{
          height: "100vh",
          overflowY: "scroll",
        }}>
        {_renderLineChart()}
        {_renderRevenueDetail()}
        {_renderTable()}
        <DetailError show={!!error} message={error} />
      </div>
      <Loading show={loading} />
    </div>
  );
};

export default InfiniteKitchenDetail;
