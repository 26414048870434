import React, { useState, useEffect } from "react";
import { Calendar as CalendarComp, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Modal, Card, ListGroupItem, ListGroup, Alert } from "react-bootstrap";
import {
  BookingTextBreak,
  BookingAction,
  BookingLabelDetail,
  DateFormat,
  BookingPriceRemark,
} from "../confirm-booking";
import Loading from "../../components/Loading";
import Networks from "../../network";
import "./index.scss";
import middlewares from "../../middlewares";

const localizer = momentLocalizer(moment);

const Calendar = props => {
  const [show, setShow] = useState(false);
  const [booking, setBooking] = useState({
    id: null,
    event_name: "",
    event_detail: "",
    customer_name: "",
    category_name: "",
    no_id: "",
    type: "",
    shooting_date: null,
    location: "",
    remark: "",
    telno: "",
    tax_id: "",
    updated_at: "",
    price: "",
  });
  const [loadingList, setLoadingList] = useState(true);
  const [events, setEvents] = useState([]);

  useEffect(() => _loadEvents(), []);

  const _loadEvents = () => {
    document.title = "Equipment Rental";
    Networks.getEvents()
      .then(j => {
        setLoadingList(false);
        if (j?.statusOk && !!j?.data?.length) {
          setEvents(_mapEvents(j.data));
        }
      })
      .catch(() => {
        setLoadingList(false);
      });
  };

  const _mapEvents = events => {
    return events.map(v => ({
      ...v,
      id: v.event_id,
      title: v.event_title,
      start: moment(v.start_date).toDate(),
      end: moment(v.end_date).toDate(),
    }));
  };

  const _setBooking = data => {
    setBooking({
      ...booking,
      ...data,
    });
  };

  const _loadEventDetail = ({ event_title, event_id }) => {
    if (_displayBookingEventDetailIfhas(event_id)) return;

    setLoadingList(true);
    Networks.getEventDetail(event_id)
      .then(j => {
        setLoadingList(false);
        const bookingDetail = {
          id: j.data.id,
          event_name: j.data.event_title,
          event_detail: j.data.detail,
          no_id: j.data.no_id,
          category_name: j.data.category?.name,
          customer_name: j.data.customer_name,
          shooting_date: j.data.shooting_date,
          location: j.data.location,
          remark: j.data.remarks,
          telno: j.data.telno,
          tax_id: j.data.tax_id,
          type: j.data.type,
          user_approved_name: j.data.member_approved?.name,
          updated_at: j.data.updated_at,
          price: j.data.price,
        };
        _updateBookingEventDetail(event_id, bookingDetail);
        setShow(true);
        _setBooking(bookingDetail);
      })
      .catch(() => {
        setShow(true);
        _setBooking({ id: null, event_name: event_title });
        setLoadingList(false);
      });
  };

  const _updateBookingEventDetail = (event_id, bookingDetail) => {
    setEvents(
      events.map(v => {
        if (v.id === event_id) {
          return {
            ...v,
            bookingDetail: bookingDetail,
          };
        } else {
          return v;
        }
      })
    );
  };

  const _displayBookingEventDetailIfhas = event_id => {
    const event = events.find(v => v.id === event_id);
    if (typeof event?.bookingDetail?.id === "number") {
      setLoadingList(false);
      setShow(true);
      _setBooking(event.bookingDetail);
      return true;
    } else {
      return false;
    }
  };

  const _isVIP = () => props.user?.type?.name === "vip";

  const _confirm = (event, confirm) => {
    event.preventDefault();
    setShow(false);
    setLoadingList(true);
    Networks.confirmBooking(props.user?.lineId, booking.id, confirm)
      .then(r => {
        if (r.statusOk) _loadEvents();
      })
      .catch(e => {
        setShow(false);
        setLoadingList(false);
      });
  };

  const _renderModal = () => {
    return (
      <Modal
        className="fontSarabun"
        show={show}
        onHide={() => setShow(false)}
        centered
      >
        {!booking.id ? (
          <>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontWeight: "bold" }}>
                {booking.event_name}
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Alert className="mb-0" variant="danger">
                ไม่พบข้อมูล
              </Alert>
            </Modal.Footer>
          </>
        ) : (
          <>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontWeight: "bold" }}>
                {booking.event_name}
                <Card.Subtitle className="mt-3">
                  <BookingLabelDetail
                    label="ประเภท"
                    data={booking.category_name}
                  />
                  <BookingLabelDetail label="ID" data={booking.no_id} />
                  <BookingLabelDetail label="Tel" data={booking.telno} />
                  <BookingLabelDetail label="TaxID" data={booking.tax_id} />
                </Card.Subtitle>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: 0 }}>
              <ListGroup className="list-group-flush">
                <ListGroupItem>
                  <div>
                    <span className="mb-0">ผู้เช่า</span>
                    <Card.Title style={{ fontWeight: "bold" }}>
                      {booking.customer_name}
                    </Card.Title>
                  </div>
                </ListGroupItem>
                <ListGroupItem>
                  <BookingLabelDetail
                    label="วันถ่ายทำ"
                    data={
                      booking.shooting_date
                        ? moment(booking.shooting_date).format(DateFormat)
                        : null
                    }
                  />
                </ListGroupItem>
                <ListGroupItem>
                  <BookingLabelDetail label="สถานที่" data={booking.location} />
                </ListGroupItem>
                <ListGroupItem>
                  <BookingTextBreak
                    label="รายละเอียด"
                    text={booking.event_detail}
                    toggle={false}
                  />
                </ListGroupItem>
                <ListGroupItem>
                  <BookingLabelDetail label="ราคา" data={booking.price} />
                  <BookingPriceRemark />
                </ListGroupItem>
              </ListGroup>
            </Modal.Body>
            <Modal.Footer>
              {booking?.type !== "normal" && (
                <BookingAction
                  booking={booking}
                  canApprove={_isVIP()}
                  onConfirm={_confirm}
                />
              )}
            </Modal.Footer>
          </>
        )}
      </Modal>
    );
  };

  const _getEventStyle = e => {
    let bg;
    switch (e?.type) {
      case "need-approve":
        bg = "#fdbf5d";
        break;
      case "approved":
        bg = "#00bf71";
        break;
      case "reject":
        bg = "#ff3752";
        break;
      default:
        bg = "#58b3ff";
    }
    const style = {
      backgroundColor: bg,
      color: "#fff",
    };
    return { style };
  };

  const _remarkColor = (type, remark) => {
    return (
      <div className="remark-type-item">
        <div
          className="remark-type-color"
          style={{
            backgroundColor: _getEventStyle({ type }).style.backgroundColor,
            color: _getEventStyle({ type }).style.backgroundColor,
          }}
        />
        <span style={{ fontSize: 10 }}>{remark}</span>
      </div>
    );
  };

  return (
    <div>
      <CalendarComp
        style={{ height: window.innerHeight - 32 }}
        events={events}
        eventPropGetter={_getEventStyle}
        startAccessor="start"
        endAccessor="end"
        defaultDate={moment().toDate()}
        localizer={localizer}
        onSelectEvent={e => {
          _loadEventDetail({ event_id: e.id, event_title: e.title });
        }}
      />
      <div className="remark-type-container">
        {_remarkColor("normal", "สีฟ้าไม่ต้องอนุมัติ")}
        {_remarkColor("need-approve", "สีเหลืองรออนุมัติ")}
        {_remarkColor("approved", "สีเขียวอนุมัติแล้ว")}
      </div>
      {_renderModal()}
      <Loading show={loadingList} />
    </div>
  );
};

export default middlewares.withLogin(process.env.REACT_APP_LIFF_ID_CALENDAR, {
  permissionAccess: {
    message: "ข้อมูลการเช่ากล้องเฉพาะผู้ที่เกี่ยวข้อง",
    accessPermission: "equipment-rental",
  },
})(Calendar);
