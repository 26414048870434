import {
  NetworkStatus,
  BusinessResponse,
  MonthResponse,
  RevenueYearMonthResponse,
  RevenueLatestResponse,
  LoginResponse,
  CompanyListResponse,
  SendOtpResponse,
  VerifyOtpResponse,
  BusinessGroupResponse,
  PinvestmentRevenuResponse,
  GroupRevenuResponse,
  ProjectDetailResponse,
  BusinessGroupV2,
  BusinessDetail,
  DataResponse,
  BusinessGroupDetailV2,
  PinvestmentDetailV2,
  ProjectResponse,
  ProjectGraphResponse,
  E29ProjectExpenseResponse,
  InfiniteKitchenRevenue,
  InfiniteKitchenRevenueNew,
  ErpRevenue,
  Luxury29,
  Luxury29V4
} from "./models";

const Endpoint = process.env.REACT_APP_API_ENDPOINT + "/equipment";
const Luxury29Endpoint = process.env.REACT_APP_API_LUXURY29_ENDPOINT
const defaultHeaders = headers => ({
  Accept: "application/json",
  "Content-Type": "application/json",
  ...(headers || {}),
});
const isStatusCodeOk = sc => sc >= 200 && sc < 300;
const Networks = {
  sendTelno: async (telno): Promise<SendOtpResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/member/telno/login`, {
        method: "POST",
        headers: defaultHeaders(),
        body: JSON.stringify({
          telno,
        }),
      });
      statusCode = res.status;
      data = await res.json();
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data,
    };
  },
  verifyOtp: async (line_id, otp, telno): Promise<VerifyOtpResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/member/telno/verify`, {
        method: "POST",
        headers: defaultHeaders(),
        body: JSON.stringify({
          otp,
          line_id,
          telno,
        }),
      });
      statusCode = res.status;
      data = await res.json();
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data: data?.data,
    };
  },
  verifyOtpWithToken: async (
    line_id,
    otp,
    telno,
    token,
  ): Promise<VerifyOtpResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/member/telno/verify/update`, {
        method: "POST",
        headers: defaultHeaders({
          Authorization: "Bearer " + token,
        }),
        body: JSON.stringify({
          otp,
          line_id,
          telno,
        }),
      });
      statusCode = res.status;
      data = await res.json();
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data: data?.data,
    };
  },
  lineIdUpdateWithToken: async (line_id, token): Promise<NetworkStatus> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/member/lineid/update`, {
        method: "POST",
        headers: defaultHeaders({
          Authorization: "Bearer " + token,
        }),
        body: JSON.stringify({
          line_id,
        }),
      });
      statusCode = res.status;
      data = await res.json();
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data: data?.data,
    };
  },
  getBookingDetail: async bookingid => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/booking/${bookingid}`);
      statusCode = res.status;
      data = await res.json();
      data = data?.data;
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data,
    };
  },
  confirmBooking: async (line_id, bookingid, confirm) => {
    let statusCode;
    try {
      const res = await fetch(
        `${Endpoint}/api/v1/booking/${bookingid}/confirm`,
        {
          method: "POST",
          headers: defaultHeaders(),
          body: JSON.stringify({
            confirm,
            line_id,
          }),
        },
      );
      statusCode = res.status;
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
    };
  },
  getEvents: async () => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/calendar/events`);
      statusCode = res.status;
      data = await res.json();
      data = data?.data;
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data,
    };
  },
  getEventDetail: async event_id => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/calendar/events/${event_id}`);
      statusCode = res.status;
      data = await res.json();
      data = data?.data;
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data,
    };
  },
  getMemberProfile: async line_id => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/member/profile/${line_id}`);
      statusCode = res.status;
      data = await res.json();
      data = data?.data;
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data,
    };
  },
  logout: async userId => {},
  getBusiness: async (): Promise<BusinessResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/business`);
      statusCode = res.status;
      data = await res.json();
      data = data?.data;
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data,
    };
  },
  getMonthOfBusinessYear: async (business_id, year): Promise<MonthResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(
        `${Endpoint}/api/v1/business/${business_id}?year=${year}&mode=year`,
      );
      statusCode = res.status;
      data = await res.json();
      data = data?.data;
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data,
    };
  },
  getRevenue: async (business_id, query): Promise<RevenueYearMonthResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(
        `${Endpoint}/api/v1/business/${business_id}${query || ""}`,
      );
      statusCode = res.status;
      data = await res.json();
      data = data?.data;
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data,
    };
  },
  getLatestRevenue: async (query): Promise<RevenueLatestResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(
        `${Endpoint}/api/v1/business/latest${query || ""}`,
      );
      statusCode = res.status;
      data = await res.json();
      data = data?.data;
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data,
    };
  },
  pushMessages: async (line_id, messages): Promise<NetworkStatus> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/line/message/push`, {
        method: "POST",
        headers: defaultHeaders(),
        body: JSON.stringify({
          line_id,
          messages,
        }),
      });
      statusCode = res.status;
      data = await res.json();
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data,
    };
  },
  login: async (username, password, company): Promise<LoginResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/employee/login`, {
        method: "POST",
        headers: defaultHeaders(),
        body: JSON.stringify({
          username,
          password,
          company,
        }),
      });
      statusCode = res.status;
      data = await res.json();
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data: data?.data,
    };
  },
  sendOptWithToken: async (telno, token): Promise<SendOtpResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/member/send-otp`, {
        method: "POST",
        headers: defaultHeaders({
          Authorization: "Bearer " + token,
        }),
        body: JSON.stringify({
          telno,
        }),
      });
      statusCode = res.status;
      data = await res.json();
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data,
    };
  },
  companyList: async (): Promise<CompanyListResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/company/list`);
      statusCode = res.status;
      data = await res.json();
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data: data?.data,
    };
  },
  getBusinessGroup: async (q): Promise<BusinessGroupResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/business-groups${q || ""}`);
      statusCode = res.status;
      data = await res.json();
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data: data?.data,
    };
  },
  getPinvestmentRevenue: async (q): Promise<PinvestmentRevenuResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(`${Endpoint}/api/v1/business/summary${q || ""}`);
      statusCode = res.status;
      data = await res.json();
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data: data?.data,
    };
  },
  getGroupRevenue: async (business_id, q): Promise<GroupRevenuResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(
        `${Endpoint}/api/v1/business-groups/${business_id}${q || ""}`,
      );
      statusCode = res.status;
      data = await res.json();
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data: data?.data,
    };
  },
  getProjectDetail: async (project_id, q): Promise<ProjectDetailResponse> => {
    let statusCode;
    let data;
    try {
      const res = await fetch(
        `${Endpoint}/api/v1/business-project/${project_id}${q || ""}`,
      );
      statusCode = res.status;
      data = await res.json();
    } catch {}
    return {
      statusOk: isStatusCodeOk(statusCode),
      statusCode,
      data: data?.data,
    };
  },
  v2: {
    getBusinessGroup: async (
      q,
    ): Promise<
      DataResponse<{
        pinvestment_total: number,
        business_groups: BusinessGroupV2[],
        business_projects: ProjectResponse[],
      }>,
    > => {
      let statusCode;
      let data;
      try {
        const res = await fetch(`${Endpoint}/api/v2/business-groups${q || ""}`);
        statusCode = res.status;
        data = await res.json();
      } catch {}
      return {
        statusOk: isStatusCodeOk(statusCode),
        statusCode,
        data: data?.data,
      };
    },
    getBusinessGroupDetail: async (
      groupId,
      q,
    ): Promise<DataResponse<BusinessGroupDetailV2>> => {
      let statusCode;
      let data;
      try {
        const res = await fetch(
          `${Endpoint}/api/v2/business-groups/${groupId}${q || ""}`,
        );
        statusCode = res.status;
        data = await res.json();
      } catch {}
      return {
        statusOk: isStatusCodeOk(statusCode),
        statusCode,
        data: data?.data,
      };
    },
    getPinvestmentDetail: async (
      q,
    ): Promise<DataResponse<PinvestmentDetailV2>> => {
      let statusCode;
      let data;
      try {
        const res = await fetch(
          `${Endpoint}/api/v2/business-groups/summary${q || ""}`,
        );
        statusCode = res.status;
        data = await res.json();
      } catch {}
      return {
        statusOk: isStatusCodeOk(statusCode),
        statusCode,
        data: data?.data,
      };
    },
    getBusinessDetail: async (
      business_id,
      q,
    ): Promise<DataResponse<BusinessDetail>> => {
      let statusCode;
      let data;
      try {
        const res = await fetch(
          `${Endpoint}/api/v2/business/${business_id}${q || ""}`,
        );
        statusCode = res.status;
        data = await res.json();
      } catch {}
      return {
        statusOk: isStatusCodeOk(statusCode),
        statusCode,
        data: data?.data,
      };
    },
    getProjectDetail: async (
      project_id,
      q,
    ): Promise<
      DataResponse<{
        project: ProjectResponse,
        project_revenues: ProjectGraphResponse[],
      }>,
    > => {
      let statusCode;
      let data;
      try {
        const res = await fetch(
          `${Endpoint}/api/v2/business-project/${project_id}${q || ""}`,
        );
        statusCode = res.status;
        data = await res.json();
      } catch {}
      return {
        statusOk: isStatusCodeOk(statusCode),
        statusCode,
        data: data?.data,
      };
    },
    getProjectDetailExpense: async (
      costCenterNo,
    ): Promise<DataResponse<E29ProjectExpenseResponse>> => {
      let statusCode;
      let data;
      try {
        const res = await fetch(
          `${Endpoint}/api/v2/e29/project/${costCenterNo}`,
        );
        statusCode = res.status;
        data = await res.json();
      } catch {}
      return {
        statusOk: isStatusCodeOk(statusCode),
        statusCode,
        data: data?.data,
      };
    },
    getRevenueDailyInfiniteKitchen: async (
      slug,
      yearMonth, //2020-10
    ): Promise<DataResponse<InfiniteKitchenRevenue>> => {
      let statusCode;
      let data;
      try {
        const res = await fetch(
          `${Endpoint}/api/v2/pos/receipt/items?prefix=${slug}&year_month=${yearMonth}`,
        );
        statusCode = res.status;
        data = await res.json();
      } catch {}
      return {
        statusOk: isStatusCodeOk(statusCode),
        statusCode,
        data: data?.data,
      };
    },
    getRevenueDailyInfiniteKitchenNew: async (
      slug,
      yearMonth,
    ): Promise<DataResponse<InfiniteKitchenRevenueNew>> => {
      let statusCode;
      let data;
      try {
        const res = await fetch(
          `${Endpoint}/api/v2/pos/receipt/summary?prefix=${slug}&year_month=${yearMonth}`,
        );
        statusCode = res.status;
        data = await res.json();
      } catch {}
      return {
        statusOk: isStatusCodeOk(statusCode),
        statusCode,
        data: data?.data,
      };
    },
    getErpRevenueMonthly: async (
      yearMonth,
    ): Promise<DataResponse<ErpRevenue>> => {
      let statusCode;
      let data;
      try {
        const res = await fetch(
          `${Endpoint}/api/v2/erp-revenues${yearMonth}`,
        );
        statusCode = res.status;
        data = await res.json();
      } catch {}
      return {
        statusOk: isStatusCodeOk(statusCode),
        statusCode,
        data: data?.data,
      };
    },
    getErpRevenueMonthlyDetail: async (
      CSID,
      yearMonth,
    ): Promise<DataResponse<ErpRevenue>> => {
      let statusCode;
      let data;
      try {
        const res = await fetch(
          `${Endpoint}/api/v2/erp-revenues/${CSID}${yearMonth}`,
        );
        statusCode = res.status;
        data = await res.json();
      } catch {}
      return {
        statusOk: isStatusCodeOk(statusCode),
        statusCode,
        data: data?.data,
      };
    },
  },
  luxury29: {
    getDailyRevenue: async (
      yearMonth,
    ): Promise<DataResponse<Luxury29>> => {
      let statusCode;
      let data;
      const url = `${Luxury29Endpoint}/api/report/lineoa-daily-revenue?date=${yearMonth}`
      try {
        const res = await fetch(url);
        statusCode = res.status;
        data = await res.json();
      } catch {}
      return {
        statusOk: isStatusCodeOk(statusCode),
        statusCode,
        data: data?.data,
      };
    },
    getDailyRevenueV4: async (
      yearMonth,
    ): Promise<DataResponse<Luxury29V4>> => {
      let statusCode;
      let data;
      const username = process.env.REACT_APP_API_LUXURY29_ENDPOINT_V4_USERNAME
      const password = process.env.REACT_APP_API_LUXURY29_ENDPOINT_V4_PASSWORD
      const url = `${Luxury29Endpoint}/api/v4/report/lineoa/daily-revenue?date=${yearMonth}`
      try {
        const res = await fetch(url, {
          headers: new Headers({
            "Authorization": `Basic ${btoa(`${username}:${password}`)}`
          })
        });
        statusCode = res.status;
        data = await res.json();
      } catch {}
      return {
        statusOk: isStatusCodeOk(statusCode),
        statusCode,
        data: data?.data,
      };
    },
  }
};

export default Networks;
