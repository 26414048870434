import React from "react";
import utils from "../utils";

const denied = require("../images/undraw_taken.svg");

const DenyAccess = ({ msg }) => {
  return (
    <div className="page-container flex-column justify-content-center">
      <img style={styles.image} src={denied} />
      <h5 style={styles.text}>{msg || "ไม่สามารถเข้าถึงหน้านี้ได้"}</h5>
      <button
        className="btn btn-sm btn-custom mb-3"
        style={styles.button}
        onClick={() => utils.closeApp()}
      >
        ปิด
      </button>
    </div>
  );
};

const styles = {
  image: {
    maxWidth: 200,
    marginBottom: "2rem",
  },
  text: {
    textAlign: "center",
    marginBottom: "2rem",
  },
  button: {
    fontSize: 16,
    maxWidth: 100,
    backgroundColor: "#115fa7",
    borderColor: "#115fa7",
  },
};

export default DenyAccess;
