import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const ModuleLoading = (props) => {
    return (
        <div className="text-center">
            <FontAwesomeIcon icon="circle-notch" spin style={{ color: '#115fa7', marginTop: '0.5rem' }} size="lg"/>
            <p className={`${props?.onlyIcon ? 'd-none' : ''}`} style={{marginTop: '0.5rem'}}>
                <small>{props?.msg || 'กำลังดึงข้อมูล'}</small>
            </p>
        </div>
    )
}

export default ModuleLoading