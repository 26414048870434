import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useLocation } from 'react-router-dom'
import Networks from '../../../network';
import { modelInfiniteKitchenRevenue, modelInfiniteKitchenRevenueNew } from '../viewModel';
import DefaultTable from './DefaultTable';
import ModuleLoading from '../../../components/ModuleLoading';

const TableInfiniteKitchen = (props) => {
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const yearMonth = moment(
        `${query.get("year")} ${query.get("month")}`,
        "YYYY MM",
    )
    .startOf("month")
    .toDate();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({})
    const [error, setError] = useState(null);

    useEffect(() => {
        let subscribe = { mounted: true };
        (async () => {
            await _fetchData(moment(yearMonth).format("MM"), moment(yearMonth).format("YYYY"), subscribe, props?.version || 'old')
        })()
        return () => {
            subscribe.mounted = false;
        };
    }, [query.get("year"), query.get("month")])
    
    const _fetchData = async (month, year, subscribe, version) => {
        setLoading(true);
        const type = query.get("type");
        const prefix = query.get("prefix")
        if (['mono_sei', 'greenhouse', 'greenhouse_cafe_stadium'].includes(prefix)) {
            const arg = {slug: query.get("prefix"), yearMonth: `${year}-${month}`} 
            if (type === 'business') {
                try {
                    const res = version === 'new' ? 
                        await Networks.v2.getRevenueDailyInfiniteKitchenNew(arg.slug, arg.yearMonth) : 
                        await Networks.v2.getRevenueDailyInfiniteKitchen(arg.slug, arg.yearMonth)
                    if (!subscribe.mounted) return;
                    if (res.statusCode === 200) {
                        setData(version === 'new' ? modelInfiniteKitchenRevenueNew(year, month, res.data) : modelInfiniteKitchenRevenue(year, month, res.data));
                        setError(null);
                    } else {
                        setError(
                          "ดึงข้อมูลไม่สำเร็จ code: " +
                            (res?.statusCode || "Unknown"),
                        );
                        setData(null);
                    }
                    setLoading(false);
                } catch (e) {
                    console.log(e)
                    _onFetchError(subscribe)
                }
            }
        } else {
            setError("ดึงข้อมูลไม่สำเร็จ code: Invalid prefix query");
            setLoading(false);
        }
    };

    const _onFetchError = subscribe => {
        if (!subscribe.mounted) return;
        setLoading(false);
    };

    const _renderContent = version => {
        if (loading || !!error) return null;
        return (
            <DefaultTable>
                {version === 'new' ? 
                (
                    <>
                        {data?.days?.map((day, i) => (
                            <tr
                                key={i}
                                style={{
                                    backgroundColor: day.isWeekend ? "#eee" : "#fff",
                                }}>
                                <td style={{
                                    paddingLeft: '2px',
                                    paddingRight: '2px'
                                }}>{day.day}</td>
                                <td>
                                    {!!day?.groups?.length ? (
                                    <>
                                        {
                                            day?.groups?.map((v, j) => {
                                                let pos = j + 1
                                                let text = v?.name || '-'
                                                if (text !== '-') {
                                                    let text_split = text.split('.')
                                                    if (text_split.length > 1) {
                                                        text = text_split[1]
                                                    }
                                                }
                                                return (
                                                    <div key={j} className="preparing-item">
                                                        <div className="preparing-item-total">
                                                            <span className="">{`${pos}.`}{text}</span>
                                                            <span className="ml-3">{v?.sub_total || '-'}</span>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        <div className="preparing-item">
                                            <div className="preparing-item-total">
                                                <span className="">{day?.service_charge?.pos}.Service Charge</span>
                                                <span className="ml-3">{day?.service_charge?.val || '-'}</span>
                                            </div>
                                        </div>
                                        <div className="preparing-item">
                                            <div className="preparing-item-total">
                                                <span className="">{day?.discount?.pos}.Discount</span>
                                                <span className="ml-3 text-danger">{day?.discount?.val ? `(${day?.discount?.val})` : '-'}</span>
                                            </div>
                                        </div>
                                    </>
                                    ) : (
                                    <div className="preparing-item preparing-item-line">
                                        <span className="">{"-"}</span>
                                    </div>
                                    )}
                                </td>
                                <td>{day?.total || "0.00"}</td>
                            </tr>
                        ))}
                        <tr>
                            <td className="font-weight-bold" colSpan={2}>Total</td>
                            <td className="font-weight-bold">
                                {data?.totalMonth || "0.00"}
                            </td>
                        </tr>
                    </>
                ) : 
                (
                    <>
                        {data?.days?.map((day, i) => (
                            <tr
                                key={i}
                                style={{
                                backgroundColor: day.isWeekend ? "#eee" : "#fff",
                                }}>
                                <td>{day.day}</td>
                                <td>
                                {!!day?.items?.length ? (
                                    day?.items?.map((v, j) => (
                                    <div key={j} className="preparing-item">
                                        <span className="">{v.name}</span>
                                        <span className="ml-3">{v.amount}</span>
                                    </div>
                                    ))
                                ) : (
                                    <div className="preparing-item preparing-item-line">
                                    <span className="">{"-"}</span>
                                    </div>
                                )}
                                </td>
                                <td>{day?.total || "0.00"}</td>
                            </tr>
                        ))}
                        <tr>
                            <td className="font-weight-bold" colSpan={2}>Total</td>
                            <td className="font-weight-bold">
                                {data?.totalMonth || "0.00"}
                            </td>
                        </tr>
                    </>
                )}
            </DefaultTable>
        )
    }
    
    return (
        <>
            {_renderContent(props?.version || 'old')}
            {!!error && <div className="text-center"><small>{error}</small></div>}
            {loading && <ModuleLoading />}
        </>
    )
}

export default TableInfiniteKitchen