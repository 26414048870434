import React, { useState, useEffect } from "react";
import {
  Container,
  Alert,
  Card,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import Networks from "../../network";
import utils from "../../utils";
import QueryString from "query-string";
import moment from "moment";
import Loading from "../../components/Loading";
import middlewares from "../../middlewares";

export const TimeFormat = "HH:mm";
export const DateFormat = "Do MMMM YYYY";

const ConfirmBooking = props => {
  const [loading, setLoading] = useState(true);
  const [sendConfirm, setSendConfirm] = useState(false);
  const [booking, setBooking] = useState({
    id: "",
    event_name: "",
    event_detail: "",
    customer_name: "",
    category_name: "",
    no_id: "",
    type: "",
    user_approved_name: "",
    shooting_date: null,
    location: "",
    remark: "",
    tel: "",
    tax_id: "",
    updated_at: "",
    price: "",
  });

  useEffect(() => _init(), []);

  const _setBookingData = data => {
    setBooking({
      ...booking,
      ...data,
    });
  };

  const _init = () => {
    document.title = "Equipment Rental";
    const search = window.location.search;
    let urlQuery = search.replace("?", "");
    const queries = QueryString.parse(urlQuery);
    _setBookingData({ id: queries.bookingid });
    Networks.getBookingDetail(queries.bookingid)
      .then(j => {
        setLoading(false);
        _setBookingData({
          id: j.data.id,
          event_name: j.data.event_title,
          event_detail: j.data.detail,
          category_name: j.data.category?.name,
          no_id: j.data.no_id,
          customer_name: j.data.customer_name,
          shooting_date: j.data.shooting_date,
          location: j.data.location,
          remark: j.data.remarks,
          tel: j.data.telno,
          tax_id: j.data.tax_id,
          type: j.data.type,
          user_approved_name: j.data.member_approved?.name,
          updated_at: j.data.updated_at,
          price: j.data.price,
        });
      })
      .catch(() => {
        _setBookingData({ id: null });
        setLoading(false);
      });
  };

  const _confirm = (event, confirm) => {
    event.preventDefault();
    setSendConfirm(true);
    Networks.confirmBooking(props.user?.lineId, booking.id, confirm)
      .then(r => {
        if (r.statusOk) {
          utils.closeApp(
            `${confirm ? "คุณ อนุมัติ" : "คุณ ไม่อนุมัติ"}, ${
              booking.event_name
            }`
          );
        } else {
          setSendConfirm(false);
        }
      })
      .catch(e => {
        setSendConfirm(false);
      });
  };

  const _renderAdditional = () => {
    if (!booking.additional_equipments?.length) return null;

    return (
      <ListGroupItem>
        <p className="mb-0">รายการอุปกรณ์เพิ่มเติม</p>
        {booking.additional_equipments?.map(v => (
          <div>{v.name}</div>
        ))}
      </ListGroupItem>
    );
  };

  return loading ? (
    <Loading show={true} backdropColor="transparent" variant="primary" />
  ) : !booking.id ? (
    <div className="page-container">
      <Container>
        <h1 style={styles.notFound}>ไม่พบข้อมูล</h1>
      </Container>
    </div>
  ) : (
    <>
      <Container style={styles.container}>
        <Card style={styles.card}>
          <Card.Body>
            <Card.Title style={styles.cardTitle}>
              {booking.event_name}
            </Card.Title>
            <Card.Subtitle className="mt-3">
              <BookingLabelDetail label="ประเภท" data={booking.category_name} />
              <BookingLabelDetail label="ID" data={booking.no_id} />
              <BookingLabelDetail label="Tel" data={booking.tel} />
              <BookingLabelDetail label="TaxID" data={booking.tax_id} />
            </Card.Subtitle>
          </Card.Body>
          <ListGroup className="list-group-flush">
            <ListGroupItem style={{ height: 0, padding: 0 }} />
            <ListGroupItem>
              <div>
                <span className="mb-0">ผู้เช่า</span>
                <Card.Title style={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  {booking.customer_name}
                </Card.Title>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <BookingLabelDetail
                label="วันถ่ายทำ"
                data={moment(booking.shooting_date).format(DateFormat)}
              />
            </ListGroupItem>
            <ListGroupItem>
              <BookingTextBreak
                label="รายละเอียด"
                text={booking.event_detail}
              />
            </ListGroupItem>
            <ListGroupItem>
              <BookingTextBreak label="หมายเหตุ" text={booking.remark} />
            </ListGroupItem>
            {_renderAdditional()}
            <ListGroupItem>
              <BookingLabelDetail label="ราคา" data={booking.price} />
              <BookingPriceRemark />
            </ListGroupItem>
          </ListGroup>
          <Card.Body>
            <BookingAction booking={booking} onConfirm={_confirm} />
          </Card.Body>
        </Card>
      </Container>
      <Loading show={sendConfirm} variant="primary" />
    </>
  );
};

export const BookingPriceRemark = () => {
  return (
    <p style={{ fontSize: "0.8rem", marginBottom: 0 }}>
      *ราคาหลังหักส่วนลด ไม่รวมภาษี
    </p>
  );
};

export const BookingLabelDetail = ({ label, data = "" }) => {
  return (
    <div>
      <p className="mb-1">
        {label}: <strong className="ml-1">{data ? data : `-`}</strong>
      </p>
    </div>
  );
};

export const BookingTextBreak = ({
  label,
  text = "",
  limit = 120,
  toggle = true,
}) => {
  const [show, setShow] = useState(false);
  const isOver = text?.length > limit;
  const data = !isOver || show ? text : text?.substr(0, limit);
  const toggleShow = () => setShow(!show);

  return (
    <div onClick={toggle ? toggleShow : undefined}>
      <p className="mb-0">{label}</p>
      {data ? data : "-"}
      {isOver && !!data && (
        <>
          {show ? "" : "..."}
          {toggle && (
            <i style={{ color: "#999" }}>{show ? " ซ่อน" : "เพิ่มเติ่ม"}</i>
          )}
        </>
      )}
    </div>
  );
};

export const BookingAction = ({
  booking,
  canApprove = true,
  onConfirm = () => {},
}) => {
  const by = (
    <div>
      <span style={{ marginRight: 4 }}>คุณ</span>
      <span style={styles.userApproved}>{booking.user_approved_name}</span>
    </div>
  );
  const byDate = (
    <div>
      <span style={{ marginRight: 4 }}>วันที่อนุมัติ</span>
      <span style={{ fontWeight: "bold" }}>
        {moment(booking.updated_at).format(DateFormat)}
      </span>
      <div>
        <span style={{ marginRight: 4 }}>เวลาอนุมัติ</span>
        <span style={{ fontWeight: "bold" }}>
          {moment(booking.updated_at).format(TimeFormat)}
        </span>
      </div>
    </div>
  );

  return booking.type === "normal" ? null : booking.type === "approved" ? (
    <Alert variant="success">
      ได้รับการอนุมัติโดย {by} {byDate}
    </Alert>
  ) : booking.type === "reject" ? (
    <Alert variant="danger">
      ไม่ได้รับการอนุมัติโดย {by} {byDate}
    </Alert>
  ) : !canApprove ? (
    <Alert variant="warning">รอการอนุมัติ</Alert>
  ) : (
    <div style={{ display: "flex", width: "100%" }}>
      <button className="btn btn-custom" onClick={e => onConfirm(e, true)}>
        อนุมัติ
      </button>
      <div style={{ width: "1.25rem" }} />
      <button
        className="btn btn-custom"
        style={{ backgroundColor: "#fff", color: "#0b1340" }}
        onClick={e => onConfirm(e, false)}
      >
        ไม่อนุมัติ
      </button>
    </div>
  );
};

const styles = {
  icon: {
    marginRight: ".5rem",
  },
  container: {
    paddingTop: 15,
    paddingBottom: 15,
  },
  card: {
    borderRadius: "1rem",
    border: "none",
  },
  cardTitle: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginBottom: 0,
  },
  notFound: {
    fontSize: "3rem",
    textAlign: "center",
  },
  userApproved: {
    fontWeight: "bold",
  },
  customerDetailItem: {
    marginBottom: 0,
  },
};

export default middlewares.withLogin(
  process.env.REACT_APP_LIFF_ID_CONFIRM_BOOKING
)(ConfirmBooking);
