import React, { useState, useEffect } from "react";
import { Container, Form, Spinner, Alert } from "react-bootstrap";
import "./index.scss";
import { Page } from ".";
import FormValidate from "../../components/FormValidate";
import Networks from "../../network";
import utils from "../../utils";

const Username = props => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    uname: "",
    upass: "",
    company: "",
  });
  const [companyList, setCompanyList] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    _fetchCompany();
  }, []);

  const _fetchCompany = () => {
    props.setLoading(true);
    Networks.companyList()
      .then(res => {
        props.setLoading(false);
        if (res.statusOk && res.data.length > 0) {
          _onValueChange("company", res.data[0].slug);
          setCompanyList(res.data);
        } else if (res.statusOk && res.data.length === 0) {
          setError("โหลดข้อมูลไม่สำเร็จ");
          setCompanyList(null);
        } else {
          setError(
            "โหลดข้อมูลไม่สำเร็จ" +
              (res.statusCode ? ` code: ${res.statusCode}` : "")
          );
          setCompanyList(null);
        }
      })
      .catch(e => {
        props.setLoading(false);
        setError("เกิดข้อผิดพลาด " + e);
        setCompanyList(null);
      });
  };

  const _onClick = e => {
    e.preventDefault();
    setError(null);
    setLoading(true);
    Networks.login(form.uname, form.upass, form.company)
      .then(res => {
        if (res.statusOk && res.data?.access_token) {
          if (process.env.REACT_APP_VERIFY_OTP === "enable") {
            setLoading(false);
            props.setAuth(res.data);
            props.setPage(Page.tel);
          } else {
            Networks.lineIdUpdateWithToken(
              props.user?.lineId,
              res.data.access_token
            )
              .then(res => {
                if (res.statusOk) {
                  Networks.pushMessages(props.user?.lineId, [
                    {
                      type: "text",
                      text: "เข้าสู่ระบบสำเร็จ",
                    },
                  ])
                    .then(() => {
                      utils.closeApp();
                    })
                    .catch(() => {
                      utils.closeApp();
                    });
                } else if (res.statusCode === 503) {
                  setLoading(false);
                  setError("ประเภทผู้ใช้งานไม่สามารถใช้งานได้");
                } else {
                  setLoading(false);
                  setError("เข้าสู่ระบบไม่สำเร็จ: " + (res.data?.data || ""));
                }
              })
              .catch(e => {
                setLoading(false);
                setError("เกิดข้อผิดพลาด: " + e);
              });
          }
        } else {
          setLoading(false);
          setError("Username หรือ Password ไม่ถูกต้อง");
        }
      })
      .catch(e => {
        setLoading(false);
        setError("เกิดข้อผิดพลาด " + e);
      });
  };

  const _onValueChange = (name, value) => {
    const _state = { ...form, [name]: value };
    setForm(_state);
  };

  const _renderCompanyList = () => {
    return (
      <Form.Group className="mb-3">
        {companyList?.map((v, i) => (
          <div
            key={i}
            className={"form-check"}
            onClick={() => _onValueChange("company", v.slug)}
          >
            <input
              className="form-check-input"
              type="radio"
              name="company"
              id={"company_" + i}
              value={v.slug}
              checked={v.slug === form.company}
              onChange={() => _onValueChange("company", v.slug)}
            ></input>
            <label className="form-check-label">{v.name}</label>
          </div>
        ))}
      </Form.Group>
    );
  };

  return (
    <div className="page-container username-page">
      <Container>
        <Form className="form-content">
          <h1 className="title-header">เข้าสู่ระบบ</h1>
          {companyList !== null ? (
            <>
              <Alert
                style={{ fontSize: "0.8rem" }}
                variant={!!error ? "danger" : "primary"}
              >
                {error || "โปรด login เพื่อเข้าสู่ระบบ"}
              </Alert>
              {_renderCompanyList()}
              <FormValidate
                validates={[
                  {
                    rule: v => v.trim() !== "",
                    msg: "Required",
                  },
                ]}
                initForm={{ uname: "", upass: "" }}
                onKeyValueChange={_onValueChange}
                onSubmit={_onClick}
              >
                {({ values, errors, onChangeText, onBlur, onSubmit }) => (
                  <>
                    <Form.Group
                      style={{
                        marginBottom: "1rem",
                      }}
                    >
                      <Form.Control
                        className={
                          "none-outline" +
                          (!!errors.uname ? " form-input-error" : "")
                        }
                        disabled={loading}
                        name="uname"
                        value={values.uname}
                        placeholder="Username"
                        onChange={onChangeText}
                        onBlur={onBlur}
                      />
                      <div className="helper-label">
                        *รหัสพนักงานระบุแค่ตัวเลข เช่น PIN123 ให้กรอกแค่ 123
                      </div>
                      {!!errors.uname && (
                        <div className="form-error">{errors.uname}</div>
                      )}
                    </Form.Group>
                    <Form.Group>
                      <Form.Control
                        className={
                          "none-outline" +
                          (!!errors.upass ? " form-input-error" : "")
                        }
                        disabled={loading}
                        name="upass"
                        value={values.upass}
                        type="password"
                        placeholder="Password"
                        onChange={onChangeText}
                        onBlur={onBlur}
                      />
                      {!!errors.upass && (
                        <div className="form-error">{errors.upass}</div>
                      )}
                    </Form.Group>
                    <button
                      className="btn btn-custom mb-3"
                      disabled={loading}
                      onClick={onSubmit}
                    >
                      {loading && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                      {" เข้าสู่ระบบ"}
                    </button>
                  </>
                )}
              </FormValidate>
            </>
          ) : (
            <Alert style={{ fontSize: "0.8rem" }} variant="danger">
              {error}
            </Alert>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default Username;
