import React, { useState } from "react";

const FormValidate = props => {
  const [form, setForm] = useState(props.initForm || {});
  const [errors, setErrors] = useState({});

  const _validateForms = () => {
    let _errors = Object.keys(form)
      ?.map(k => _validateForm(k, form[k]))
      ?.reduce((p, c) => ({ ...p, ...c }));
    setErrors(_errors || {});
    return _errors;
  };
  const _validateForm = (name, value) => {
    let _errors = props.validates
      ?.map(v => {
        let e = {};
        if (v.rule(value)) {
          delete e[name];
        } else {
          e[name] = v.msg;
        }
        return e;
      })
      ?.reduce((p, c) => ({ ...c, ...p }));
    return {
      ...errors,
      ..._errors,
    };
  };
  const _onChangeText = e => {
    const name = e.target.name;
    const value = e.target.value;

    const _state = { ...form, [name]: value };
    if (!!errors[name]) delete errors[name];
    setForm(_state);
    props.onKeyValueChange && props.onKeyValueChange(name, value);
  };
  const _onBlur = e => {
    const name = e.target.name;
    const value = e.target.value;
    setErrors(_validateForm(name, value));
  };

  const _onSubmit = e => {
    e.preventDefault();
    if (Object.keys(_validateForms())?.length > 0) {
      return;
    }
    props.onSubmit && props.onSubmit(e);
  };

  return (
    <>
      {props.children({
        values: form,
        errors: errors,
        onChangeText: _onChangeText,
        onBlur: _onBlur,
        onSubmit: _onSubmit,
      })}
    </>
  );
};

export default FormValidate;
