import React, { useState, useEffect } from "react";
import { Form, Container, Spinner } from "react-bootstrap";
import Networks from "../../network";
import utils from "../../utils";

const liff = window.liff;

const Logout = () => {
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => _init(), []);

  const _init = () => {
    liff.init({ liffId: "liff_id_of_this_route" }, async data => {
      let profile = await liff.getProfile();
      setUserId(profile.userId);
    });
  };

  const _logout = event => {
    event.preventDefault();

    setLoading(true);
    Networks.logout(userId)
      .then(r => {
        if (r.status === 200) {
          utils.closeApp("ออกจากระบบสำเร็จ");
        } else {
          utils.closeApp("ออกจากระบบไม่สำเร็จ");
        }
      })
      .catch(e => {
        utils.closeApp("ออกจากระบบไม่สำเร็จ");
      });
  };

  return (
    <div className="page-container">
      <Container
        style={{
          maxWidth: "420px"
        }}
      >
        <Form className="content">
          <h1 className="title-header">ออกจากระบบ</h1>
          <button
            className="btn btn-custom"
            disabled={loading}
            onClick={_logout}
          >
            {loading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {" ออกจากระบบ"}
          </button>
        </Form>
      </Container>
    </div>
  );
};

export default Logout;
