import moment from "moment";
import { randomColor } from "randomcolor";
import {
  ProjectResponse,
  BusinessGroupV2,
  BusinessDetail,
  ProjectGraphResponse,
  E29ProjectExpense,
  E29ProjectExpenseResponse,
  InfiniteKitchenRevenue,
  InfiniteKitchenRevenueNew,
  Luxury29,
  Luxury29V4
} from "../../network/models";

export const KeyCategories = {
  revenue: "revenue",
  cost: "cost",
  opex: "opex",
};
export const infinitekitchenSlug = "infinite-kitchen";
export const luxury29Slug = "luxury29";
export const isDateMyAccount = (
  date: moment.MomentInput,
  format?: moment.MomentFormatSpecification,
) =>
  moment(date, format).isSameOrAfter(moment("20200801", "YYYYMMDD"), "month");

export const parseMonth = date => {
  return {
    no: moment(date).format("MM"),
    name: moment(date).format("MMM"),
    fullName: moment(date).format("MMMM"),
  };
};

export const parseYear = date => {
  return moment(date).format("YYYY");
};

export const fixed = (num, fix = 2) => {
  return Number(
    Math.round(Math.round(num) / 1000000 + `e+${fix}`) + `e-${fix}`,
  ).toFixed(fix);
};

export const numberFormat = (num, isNumber = false) => {
  try {
    if (typeof num === "number") {
      num = Number(Math.round(num + `e+2`) + `e-2`).toFixed(2);
      if (isNumber) {
        return Number(num)
      } else {
        var parts = num.split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
      }
    } else {
      if (isNumber) {
        return 0
      } else {
        return "0.00";
      }
    }
  } catch {
    if (isNumber) {
      return 0
    } else {
      return "0.00";
    }
  }
};

export const modelDashboard = (data: any[]) => {
  const colors = ["#f25f5c", "#ffe066", "#247ba0", "#F4743B", "#70c1b3"];
  return data
    ?.map((v, i) => {
      if (v.slug === "entertainment-29") {
        return {
          ...v,
          color: randomColor({ hue: colors[i % colors.length] }),
          position: 0,
        };
      } else if (v.slug === infinitekitchenSlug) {
        return {
          ...v,
          color: randomColor({ hue: colors[i % colors.length] }),
          position: 1,
        };
      } else {
        return {
          ...v,
          color: randomColor({ hue: colors[i % colors.length] }),
          position: 2 + i,
        };
      }
    })
    .sort((c, n) => c.position - n.position)
    .map(v => ({
      ...v,
      total: fixed(
        v?.businesses
          ?.map(v => v?.revenue?.earnings || 0)
          ?.reduce((p, c) => p + c) || 0,
      ),
      businesses: v?.businesses?.map(v => ({
        ...v,
        revenue: v.revenue
          ? {
              ...v.revenue,
              earnings: fixed(v.revenue?.earnings || 0),
            }
          : null,
      })),
    }));
};

export const modelDashboardV2 = (data: BusinessGroupV2[]) => {
  const colors = ["#f25f5c", "#ffe066", "#247ba0", "#F4743B", "#70c1b3"];
  return data
    ?.map((v, i) => {
      if (v.slug === "pinvestment") {
        return {
          ...v,
          color: "#1890ff",
          position: 0,
        };
      } else if (v.slug === "entertainment-29") {
        return {
          ...v,
          color: randomColor({ hue: colors[i % colors.length] }),
          position: 1,
        };
      } else if (v.slug === infinitekitchenSlug) {
        return {
          ...v,
          color: randomColor({ hue: colors[i % colors.length] }),
          position: 2,
        };
      } else {
        return {
          ...v,
          color: randomColor({ hue: colors[i % colors.length] }),
          position: 3 + i,
        };
      }
    })
    .sort((c, n) => c.position - n.position)
    .map(v => ({
      ...v,
      total: fixed(v.total_businesses_income || 0),
      businesses: v?.businesses?.map(b => ({
        ...b,
        name: b.name,
        revenue: !!b?.total_business_income
          ? {
              name: "รายได้",
              earnings: fixed(b?.total_business_income || 0),
            }
          : null,
      })),
    }))
    .filter(v => !!v.businesses?.length);
};

export const modelProject = (data: any[]) => {
  return data?.map(p => ({
    ...p,
    start_date_format: moment(p.start_date).format("MMM YYYY"),
    end_date_format: moment(p.end_date).format("MMM YYYY"),
    revenue: fixed(p.revenue || 0),
    cumulative_cost: fixed(p.cumulative_cost || 0),
    last_month: !!p.business_project_expenses.length
      ? `(ถึง ${moment(
          p.business_project_expenses[p.business_project_expenses.length - 1]
            .created_date,
        ).format("MMM")})`
      : "",
    business_project_expenses: p.business_project_expenses?.map(e => ({
      ...e,
      cost: fixed(e.cost || 0),
      created_date_format: moment(e.created_date).format("MMM YYYY"),
    })),
  }));
};

export const modelProjectV2 = (data: any[]) => {
  return data?.map(p => ({
    ...p,
    start_date_format: moment(p.start_date).format("MMM YYYY"),
    end_date_format: moment(p.end_date).format("MMM YYYY"),
    revenue: fixed(p.revenue || 0),
    cumulative_cost: fixed(p.cumulative_cost || 0),
    business_project_expenses: p.business_project_expenses?.map(e => ({
      ...e,
      cost: fixed(e.cost || 0),
      created_date_format: moment(e.created_date).format("MMM YYYY"),
    })),
  }));
};

export const modelRevenueDetail = data => {
  // return {
  //   ...data,
  //   revenues: data.revenues?.map(v => ({
  //     ...v,
  //     earnnigs: fixed(v.earnings || 0, 5),
  //     gross_profit: fixed(v.gross_profit || 0, 5),
  //     ebitda: fixed(v.ebitda || 0, 5),
  //     dmy: moment(v.created_date).format("D"),
  //   })),
  // };
  return {
    revenue: {
      name: "รายได้",
      unit: fixed(data.revenue?.earnings || 0),
    },
    variable_cost: {
      name: "ต้นทุนผันแปร",
      unit: fixed(data.revenue?.variable_cost || 0),
    },
    gross_profit: {
      name: "กำไรขั้นต้น",
      unit: fixed(data.revenue?.gross_profit || 0),
    },
    opex: {
      name: "หัก OPEX",
      unit: fixed(data.revenue?.opex || 0),
    },
    opex_support: {
      name: "OPEX Support",
      unit: fixed(data.revenue?.opex_support || 0),
    },
    opex_business: {
      name: "OPEX Business",
      unit: fixed(data.revenue?.opex_business || 0),
    },
    ebitda: {
      name: "EBITDA",
      unit: fixed(data.revenue?.ebitda || 0),
    },
    expense_categories: data.revenue?.expense_categories?.map(v => ({
      ...v,
      unit: fixed(v.unit || 0),
    })),
  };
};

export const modelRevenueDetailV2 = (data: BusinessDetail) => {
  const income =
    data.my_account?.revenue?.income?.map(v => ({
      type: KeyCategories.revenue,
      unit: fixed(v.Amnt || 0),
      expense_category: {
        name: (v.GLAccName || "") + ` ${v.CCTRName || ""}`,
      },
    })) || [];
  let variable_cost =
    data.my_account?.revenue?.variable_cost
      ?.filter((_, i) => i < 3)
      ?.map(v => ({
        type: KeyCategories.cost,
        unit: fixed(v.Amnt || 0),
        expense_category: {
          name: (v.GLAccName || "") + ` ${v.CCTRName || ""}`,
        },
      })) || [];
  if (data.my_account?.revenue?.variable_cost?.length > 3) {
    let other = 0;
    other = data.my_account?.revenue?.variable_cost
      ?.filter((_, i) => i >= 3)
      ?.map(v => v.Amnt)
      .reduce((p, v) => p + v, 0);
    variable_cost.push({
      type: KeyCategories.cost,
      unit: fixed(other || 0),
      expense_category: {
        name: "อื่นๆ",
      },
    });
  }
  let opex =
    data.my_account?.revenue?.opex
      ?.filter((_, i) => i < 3)
      ?.map(v => ({
        type: KeyCategories.opex,
        unit: fixed(v.Amnt || 0),
        expense_category: {
          name: (v.GLAccName || "") + ` ${v.CCTRName || ""}`,
        },
      })) || [];

  if (data.my_account?.revenue?.opex?.length > 3) {
    let other = 0;
    other = data.my_account?.revenue?.opex
      ?.filter((_, i) => i >= 3)
      ?.map(v => v.Amnt)
      .reduce((p, v) => p + v, 0);
    opex.push({
      type: "opex",
      unit: fixed(other || 0),
      expense_category: {
        name: "อื่นๆ",
      },
    });
  }

  return {
    revenue: {
      name: data.my_account?.revenue?.income?.[0]?.GLGroupName || "รายได้",
      unit: fixed(data.my_account?.total_income || 0),
    },
    variable_cost: {
      name:
        data.my_account?.revenue?.variable_cost?.[0]?.GLGroupName ||
        "ต้นทุนผันแปร",
      unit: fixed(data.my_account?.total_variable_cost || 0),
    },
    gross_profit: {
      name: "กำไรขั้นต้น",
      unit: fixed(
        Number(
          data.my_account?.total_income - data.my_account?.total_variable_cost,
        ) || 0,
      ),
    },
    opex: {
      name: data.my_account?.revenue?.opex?.[0]?.GLGroupName || "หัก OPEX",
      unit: fixed(data.my_account?.total_opex || 0),
    },
    ebitda: {
      name: "EBITDA",
      unit: fixed(
        Number(
          data.my_account?.total_income -
            data.my_account?.total_variable_cost -
            data.my_account.total_opex,
        ) || 0,
      ),
    },
    expense_categories: [...income, ...variable_cost, ...opex],
  };
};

export const modelLineChart = data => {
  try {
    // return {
    //   dates: data?.map(v => v.created_date),
    //   days: data?.map(v => moment(v.created_date).format("D MMM YYYY")),
    //   series: [
    //     {
    //       name: "รายได้",
    //       data: data.map(v => fixed(v.earnings || 0)),
    //     },
    //     {
    //       name: "กำไรขั้นต้น",
    //       data: data.map(v => fixed(v.gross_profit || 0)),
    //     },
    //     {
    //       name: "Ebitda",
    //       data: data.map(v => fixed(v.ebitda || 0)),
    //     },
    //   ],
    // };
    let series1 = {};
    let series2 = {};
    let series3 = {};
    let lastMonth = 12;
    data.map((v, i, ar) => {
      const month = parseMonth(v.created_date);
      series1[month.no] = Number(fixed(v.earnings || 0));
      series2[month.no] = Number(fixed(v.gross_profit || 0));
      series3[month.no] = Number(fixed(v.ebitda || 0));
      if (i === ar.length - 1) {
        lastMonth = Number(month.no);
      }
    });

    const getData = series => {
      return Array(lastMonth)
        .fill()
        .map((v, i) => {
          const month = i + 1;
          const key = month < 10 ? "0" + month : month;
          return series?.[key] || "0.00";
        });
    };

    return {
      dates:
        data?.length > 0
          ? Array(12)
              .fill()
              .map(
                (v, i) =>
                  `${parseYear(data[0]?.created_date)}-${
                    i > 9 ? i + 1 : "0" + (i + 1)
                  }-01 00:00:00`,
              )
          : [],
      series: [
        {
          name: "รายได้",
          data: getData(series1),
        },
        {
          name: "กำไรขั้นต้น",
          data: getData(series2),
        },
        {
          name: "Ebitda",
          data: getData(series3),
        },
      ],
    };
  } catch {
    return null;
  }
};

export const modelLineChartV2 = data => {
  try {
    let series1 = {};
    let series2 = {};
    let series3 = {};
    let lastMonth = 12;

    data.map((v, i, ar) => {
      const month = parseMonth(v.created_date);
      series1[month.no] = Number(fixed(v.total_income || 0));
      const gross_profit = (v.total_income || 0) - (v.total_variable_cost || 0);
      series2[month.no] = Number(fixed(gross_profit));
      series3[month.no] = Number(fixed(gross_profit - (v.total_opex || 0)));
      if (i === ar.length - 1) {
        lastMonth = Number(month.no);
      }
    });

    const getData = series => {
      return Array(lastMonth)
        .fill()
        .map((v, i) => {
          const month = i + 1;
          const key = month < 10 ? "0" + month : month;
          return series?.[key] || "0.00";
        });
    };

    return {
      dates:
        data.length > 0
          ? Array(12)
              .fill()
              .map((v, i) =>
                moment(data[0].created_date)
                  .startOf("year")
                  .add(i, "M")
                  .format("YYYY-MM-DD HH:mm:ss"),
              )
          : [],
      series: [
        {
          name: "รายได้",
          data: getData(series1),
        },
        {
          name: "กำไรขั้นต้น",
          data: getData(series2),
        },
        {
          name: "Ebitda",
          data: getData(series3),
        },
      ],
    };
  } catch {
    return null;
  }
};

export const modelProjectDetail = (
  data: ProjectResponse,
  expenses,
  curDate,
) => {
  const convExpense = data => {
    return (
      data?.map(v => ({
        ...v,
        name: v.GLAccName?.startsWith?.(v.ParentGLAccName || "")
          ? v.GLAccName?.split?.(`${v.ParentGLAccName} - `)?.[1] || v.GLAccName
          : v.GLAccName,
        unit: fixed(v.Amnt),
      })) || []
    );
  };
  return {
    ...data,
    start_date_format: moment(data.start_date).format("MMM YYYY"),
    end_date_format: moment(data.end_date).format("MMM YYYY"),
    revenue: {
      name: "รายได้",
      unit: fixed(data.revenue || 0),
      amount: data.revenue || 0,
    },
    cumulative_cost: {
      name: "ต้นทุนสะสม",
      unit: fixed(data.cumulative_cost || 0),
      amount: data.cumulative_cost || 0,
    },
    gross_profit: {
      name: "กำไรขั้นต้น",
      unit: fixed(data.revenue - data.cumulative_cost || 0),
      amount: data.revenue - data.cumulative_cost || 0,
    },
    cumulative_pre: {
      name: "Pre-Production",
      unit: fixed(data.cumulative_pre || 0),
      amount: data.cumulative_pre || 0,
    },
    cumulative_pro: {
      name: "Production",
      unit: fixed(data.cumulative_pro || 0),
      amount: data.cumulative_pro || 0,
    },
    cumulative_post: {
      name: "Post-Production",
      unit: fixed(data.cumulative_post || 0),
      amount: data.cumulative_post || 0,
    },
    expenses:
      expenses
        ?.map(exp => ({
          ...exp,
          pre: convExpense(exp.pre),
          pro: convExpense(exp.pro),
          post: convExpense(exp.post),
        }))
        ?.reduce(
          (p, c) => ({
            pre: [...p?.pre, ...c?.pre],
            pro: [...p?.pro, ...c?.pro],
            post: [...p?.post, ...c?.post],
          }),
          {
            pre: [],
            pro: [],
            post: [],
          },
        ) || [],
  };
};

export const modelProjectLineChart = (data: ProjectResponse) => {
  try {
    let costSeries = [];
    let revenueSeries = [];
    let cumulatetivecostSeries = [];
    let grossProfitSeries = [];
    let monthRange =
      (moment(data.end_date).diff(moment(data.start_date), "months", true) ||
        0) + 1;
    let dates = [];
    let cumulative_cost = 0;
    Array(monthRange)
      .fill()
      .map((_, i) => {
        const startDate = moment(data.start_date).add(i, "M");
        dates.push(startDate.startOf("month").format("YYYY-MM-DD 00:00:00"));
        revenueSeries.push(Number(fixed(data.revenue)));

        const v = data.business_project_expenses[i];
        if (!!v) {
          cumulative_cost += v.cost;
          costSeries.push(Number(fixed(v.cost || 0)));
          cumulatetivecostSeries.push(Number(fixed(cumulative_cost || 0)));
          grossProfitSeries.push(
            Number(fixed(data.revenue - cumulative_cost || 0)),
          );
        }
      });

    return {
      dates,
      series: [
        {
          name: "รายได้",
          data: revenueSeries,
        },
        {
          name: "ต้นทุน",
          data: costSeries,
        },
        {
          name: "ต้นทุนสะสม",
          data: cumulatetivecostSeries,
        },
        {
          name: "กำไรขั้นต้น",
          data: grossProfitSeries,
        },
      ],
    };
  } catch {
    return null;
  }
};

export const modelProjectLineChartV2 = (
  revenue,
  start_date,
  end_date,
  data: ProjectGraphResponse[],
) => {
  try {
    let costSeries = [];
    let revenueSeries = [];
    let cumulatetivecostSeries = [];
    let grossProfitSeries = [];
    let monthRange =
      (moment(end_date).diff(moment(start_date), "months", true) || 0) + 1;
    let dates = [];
    let cumulative_cost = 0;
    Array(monthRange)
      .fill()
      .map((_, i) => {
        const startDate = moment(start_date).add(i, "M");
        dates.push(startDate.startOf("month").format("YYYY-MM-DD 00:00:00"));
        revenueSeries.push(Number(fixed(revenue)));

        const v = data[i];
        if (!!v) {
          const cost = v.total_pre + v.total_pro + v.total_post;
          cumulative_cost += cost;
          costSeries.push(Number(fixed(cost || 0)));
          cumulatetivecostSeries.push(Number(fixed(cumulative_cost || 0)));
          grossProfitSeries.push(Number(fixed(revenue - cumulative_cost || 0)));
        }
      });

    return {
      dates,
      series: [
        {
          name: "รายได้",
          data: revenueSeries,
        },
        {
          name: "ต้นทุน",
          data: costSeries,
        },
        {
          name: "ต้นทุนสะสม",
          data: cumulatetivecostSeries,
        },
        {
          name: "กำไรขั้นต้น",
          data: grossProfitSeries,
        },
      ],
    };
  } catch {
    return null;
  }
};

export const modelProjectExpense = (
  curDate,
  data: E29ProjectExpenseResponse,
) => {
  const filterMonth = (data, key) =>
    data
      ?.filter(v =>
        moment(v.date, "YYYY-MM").isSameOrBefore(
          moment(curDate, "YYYY-MM"),
          "month",
        ),
      )
      .map(v => ({
        ...v,
        key,
        unit: fixed(v.expense),
      }));
  const sumExpense = data =>
    data?.map(v => v.expense)?.reduce((p, c) => p + c, 0) || 0;

  const pre = filterMonth(data?.expenses?.pre || [], "pre");
  const pro = filterMonth(data?.expenses?.prod || [], "prod");
  const post = filterMonth(data?.expenses?.post || [], "post");
  return {
    pre,
    total_pre: sumExpense(pre),
    pro,
    total_pro: sumExpense(pro),
    post,
    total_post: sumExpense(post),
    total_cost: sumExpense(pre) + sumExpense(pro) + sumExpense(post),
  };
};

export const modelProjectExpenseTransactions = (
  date,
  expensesKey,
  data: E29ProjectExpenseResponse,
) => {
  try {
    let filter =
      data?.expenses?.[expensesKey]?.find(v => v.date === date) || [];
    return {
      name: filter?.name,
      items:
        filter?.transactions?.map(v => ({
          name: v.description,
          amount: numberFormat(v.amount),
        })) || [],
      total: numberFormat(
        filter?.transactions?.map(v => v.amount)?.reduce((p, c) => p + c, 0) ||
          0,
      ),
    };
  } catch {
    return {};
  }
};

export const modelInfiniteKitchenRevenue = (
  year,
  month,
  data?: InfiniteKitchenRevenue[],
) => {
  const monthOfYear = moment(`${year}-${month}`, "YYYY-MM").startOf("month");
  return {
    totalMonth: numberFormat(
      data?.map(v => v.total_sum_amount)?.reduce((p, c) => p + c, 0),
    ),
    days: Array(monthOfYear.daysInMonth())
      .fill()
      .map((_, i) => {
        const date = monthOfYear.add(~~!!i, "day");
        const sumItem = data?.find(v =>
          date.isSame(moment(v.sales_date, "YYYY-MM-DD")),
        );
        return {
          ...sumItem,
          items:
            sumItem?.items?.map(v => ({
              ...v,
              amount: numberFormat(v?.total_amount || 0),
            })) || [],
          total: numberFormat(sumItem?.total_sum_amount || 0),
          isWeekend: [0, 6].indexOf(date.day()) > -1,
          day: i + 1,
        };
      }),
  };
};

export const modelInfiniteKitchenRevenueNew = (
  year,
  month,
  data?: InfiniteKitchenRevenueNew[],
) => {
  const monthOfYear = moment(`${year}-${month}`, "YYYY-MM").startOf("month");
  let days = Array(monthOfYear.daysInMonth())
  .fill()
  .map((_, i) => {
    const date = monthOfYear.add(~~!!i, "day");
    const sumItem = data?.find(v =>
      date.isSame(moment(v.date, "YYYY-MM-DD")),
    );
    let topup_discount = sumItem?.total_topup_discount || 0
    let groups = sumItem?.groups?.map(v => ({
      ...v,
      sub_total: numberFormat(v?.sub_total || 0, true),
      discount: numberFormat(v?.discount || 0, true),
      sc: numberFormat(v?.sc || 0, true)
    })) || []
    let total_net = numberFormat(groups?.map(v => (v.sub_total + v.sc) - v.discount)?.reduce((p, c) => p + c, 0) || 0, true)
    let total_sc = numberFormat(groups?.map(v => v.sc)?.reduce((p, c) => p + c, 0) || 0)
    let total_discount = numberFormat((groups?.map(v => v.discount)?.reduce((p, c) => p + c, 0) || 0) + topup_discount)
    total_net = total_net - topup_discount
    return {
      ...sumItem,
      groups: groups.map(v => ({
        ...v,
        sub_total: numberFormat(v?.sub_total || 0),
        discount: numberFormat(v?.discount || 0),
        sc: numberFormat(v?.sc || 0)
      })),
      total: numberFormat(total_net),
      total_number: total_net,
      isWeekend: [0, 6].indexOf(date.day()) > -1,
      day: i + 1,
      service_charge: {
        pos: (sumItem?.groups?.length || 0) + 1,
        val: total_sc
      },
      discount: {
        pos: (sumItem?.groups?.length || 0) + 2,
        val: total_discount
      },
    };
  })
  let totalMonth = numberFormat(days?.map(v => numberFormat(v.total_number, true))?.reduce((p, c) => p + c, 0) || 0)
  return {
    totalMonth, days
  };
};

export const modelLuxury29 = (
  year,
  month,
  data?: Luxury29[],
) => {
  const monthOfYear = moment(`${year}-${month}`, "YYYY-MM").startOf("month");
  let days = Array(monthOfYear.daysInMonth())
  .fill()
  .map((_, i) => {
    const date = monthOfYear.add(~~!!i, "day");
    const sumItem = data?.find(v =>
      date.isSame(moment(v.date, "YYYY-MM-DD")),
    );
    return {
      ...sumItem,
      total: numberFormat(sumItem.total),
      total_number: sumItem.total,
      isWeekend: [0, 6].indexOf(date.day()) > -1,
      total_pos: numberFormat(sumItem.total_pos), 
      total_preorder: numberFormat(sumItem.total_preorder), 
      total_discount: numberFormat(sumItem.total_discount), 
      day: i + 1,
    };
  })
  let totalMonth = numberFormat(days?.map(v => numberFormat(v.total_number, true))?.reduce((p, c) => p + c, 0) || 0)
  return {
    totalMonth, days
  };
};

export const modelLuxury29V4 = (
  year,
  month,
  data?: Luxury29V4[],
) => {
  const monthOfYear = moment(`${year}-${month}`, "YYYY-MM").startOf("month");
  let days = Array(monthOfYear.daysInMonth())
  .fill()
  .map((_, i) => {
    const date = monthOfYear.add(~~!!i, "day");
    const sumItem = data?.find(v =>
      date.isSame(moment(v.date, "YYYY-MM-DD")),
    );
    return {
      ...sumItem,
      total: numberFormat(sumItem.total),
      total_number: sumItem.total,
      isWeekend: [0, 6].indexOf(date.day()) > -1,
      total_pos: numberFormat(sumItem.total_pos), 
      total_preorder: numberFormat(sumItem.total_preorder), 
      total_discount: numberFormat(sumItem.total_discount), 
      total_event: numberFormat(sumItem.total_event),
      total_live: numberFormat(sumItem.total_live),
      day: i + 1,
    };
  })
  let totalMonth = numberFormat(days?.map(v => numberFormat(v.total_number, true))?.reduce((p, c) => p + c, 0) || 0)
  return {
    totalMonth, days
  };
};
