import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const ProductionCost = ({ name, unit, expenses = [] }) => {
  const [expand, setExpand] = useState(false);
  let history = useHistory();
  let location = useLocation();

  const expenseDetail = expense => {
    if (!expense?.transactions?.length) return;
    history.push(
      `${location.pathname}/transaction/${location.search}&expense=${expense.key}&date=${expense.date}`,
    );
  };

  return (
    <>
      <div className="cost-data" onClick={() => setExpand(!expand)}>
        <span className="cost-name">
          <FontAwesomeIcon
            className="expand-icon"
            icon={expand ? "caret-down" : "caret-right"}
          />
          {name}
        </span>
        <span className="cost-unit">{unit}</span>
      </div>
      {expenses?.length && expand ? (
        <div className="queue-list">
          {expenses.map((v, i) => (
            <div
              key={i}
              className="queue-data"
              onClick={() => expenseDetail(v)}>
              <span
                className={`${
                  !!v.transactions?.length ? "click" : ""
                } queue-name`}>
                {v.name}
              </span>
              <span className="queue-unit">{v.unit}</span>
            </div>
          ))}
        </div>
      ) : null}
      <style jsx="true">{`
        .expand-icon {
          font-size: 1.2rem;
          color: #115fa7;
          margin-right: 0.5rem;
        }
        .cost-data {
          display: flex;
          justify-content: space-between;
          color: #115fa7;
          margin-bottom: 0.2rem;
        }
        .cost-name {
        }
        .cost-unit {
          font-weight: normal;
          margin-left: 1rem;
        }
        .queue-list {
          padding-left: 1rem;
        }
        .queue-data {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.2rem;
        }
        .queue-name {
        }
        .queue-unit {
          font-weight: normal;
          margin-left: 1rem;
        }
      `}</style>
    </>
  );
};

export default ProductionCost;
