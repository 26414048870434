import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { useLocation } from 'react-router-dom'
import Networks from '../../../network';
import { modelLuxury29V4 } from '../viewModel';
import DefaultTable from './DefaultTable';
import ModuleLoading from '../../../components/ModuleLoading';

const TableLuxury29 = (props) => {
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const yearMonth = moment(
        `${query.get("year")} ${query.get("month")}`,
        "YYYY MM",
    )
    .startOf("month")
    .toDate();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({})
    const [error, setError] = useState(null);

    useEffect(() => {
        let subscribe = { mounted: true };
        (async () => {
            await _fetchData(moment(yearMonth).format("MM"), moment(yearMonth).format("YYYY"), subscribe)
        })()
        return () => {
            subscribe.mounted = false;
        };
    }, [query.get("year"), query.get("month")])
    
    const _fetchData = async (month, year, subscribe) => {
        setLoading(true);
        const type = query.get("type");
        const prefix = query.get("prefix")
        const arg = {slug: prefix, yearMonth: `${year}-${month}`} 
        if (type === 'business') {
            try {
                const res = await Networks.luxury29.getDailyRevenueV4(arg.yearMonth)
                if (!subscribe.mounted) return;
                if (res.statusCode === 200) {
                    setData(modelLuxury29V4(year, month, res.data.data));
                    setError(null);
                } else {
                    setError(
                        "ดึงข้อมูลไม่สำเร็จ code: " +
                        (res?.statusCode || "Unknown"),
                    );
                    setData(null);
                }
                setLoading(false);
            } catch (e) {
                console.log(e)
                _onFetchError(subscribe)
            }
        }
    };

    const _onFetchError = subscribe => {
        if (!subscribe.mounted) return;
        setLoading(false);
    };

    const _renderContent = version => {
        if (loading || !!error) return null;
        return (
            // <div>test</div>
            <DefaultTable>
                {data?.days?.map((day, i) => (
                    <tr
                        key={i}
                        style={{
                            backgroundColor: day.isWeekend ? "#eee" : "#fff",
                        }}>
                        <td style={{
                            paddingLeft: '2px',
                            paddingRight: '2px'
                        }}>{day.day}</td>
                        <td>
                            <div className="preparing-item">
                                <div className="preparing-item-total">
                                    <span className="">1.Shop</span>
                                    <span className="ml-3">{day?.total_pos ? `${day?.total_pos}` : '-'}</span>
                                </div>
                            </div>
                            <div className="preparing-item">
                                <div className="preparing-item-total">
                                    <span className="">2.Pre-Order</span>
                                    <span className="ml-3">{day?.total_preorder ? `${day?.total_preorder}` : '-'}</span>
                                </div>
                            </div>
                            <div className="preparing-item">
                                <div className="preparing-item-total">
                                    <span className="">3.Event</span>
                                    <span className="ml-3">{day?.total_event ? `${day?.total_event}` : '-'}</span>
                                </div>
                            </div>
                            <div className="preparing-item">
                                <div className="preparing-item-total">
                                    <span className="">4.Live</span>
                                    <span className="ml-3">{day?.total_live ? `${day?.total_live}` : '-'}</span>
                                </div>
                            </div>
                            <div className="preparing-item">
                                <div className="preparing-item-total">
                                    <span className="">5.Discount</span>
                                    <span className="ml-3 text-danger">{day?.total_discount ? `(${day?.total_discount})` : '-'}</span>
                                </div>
                            </div>
                        </td>
                        <td>{day?.total || "0.00"}</td>
                    </tr>
                ))}
                <tr>
                    <td className="font-weight-bold" colSpan={2}>Total</td>
                    <td className="font-weight-bold">
                        {data?.totalMonth || "0.00"}
                    </td>
                </tr>
            </DefaultTable>
        )
    }
    
    return (
        <>
            {_renderContent(props?.version || 'old')}
            {!!error && <div className="text-center"><small>{error}</small></div>}
            {loading && <ModuleLoading />}
        </>
    )
}

export default TableLuxury29