import React, { useState } from 'react'

const EmptyComponent = () => {
    return (<>&nbsp;</>)
}
const RenderTD = (props) => {
    const [intiData, setIntiData] = useState(props?.datas || [])
    return (
        <>
            {intiData?.items?.length > 0 && 
                <>
                    {intiData.items.map((data, i) => (
                        <React.Fragment key={`td-frag-${i}`}>
                            <td key={`${props?.prefix}-${i}`} style={{fontWeight: 'bold'}}>
                                {props?.prefix !== 'Empty' ? (
                                    <>
                                        <span className={`${data.isSpecialStyle ? 'text-center-style' : ''} ${data.fontRed ? 'text-danger' : ''}`}>
                                            {data?.val ? <span>{data.fontRed ? `(${data.val})` : data.val}</span> : <EmptyComponent />}
                                        </span>
                                        {['Opex', 'Depreciation'].includes(data.main) 
                                        && data?.children 
                                        && data?.children?.length > 0 
                                        && (
                                            <div className="child-wrap">
                                                {data?.children.map((child, i) => (
                                                    <p key={`child-td-${i}`} className="child-item">
                                                        <span>{child?.val ? child?.val : <EmptyComponent />}</span>
                                                    </p>
                                                ))}
                                            </div>
                                        )}
                                    </>
                                ) : <EmptyComponent />}
                            </td>
                        </React.Fragment>
                    ))}
                    <td>
                        {props?.prefix !== 'Empty' ? (<>
                            <span className={`${intiData?.total?.isSpecialStyle ? 'text-center-style' : ''} ${intiData?.total?.fontRed ? 'text-danger' : ''}`} style={{fontWeight: 'bold'}}>
                                {intiData?.total?.val ? <span>{intiData?.total?.fontRed ? `(${intiData.total.val})` : intiData.total.val}</span> : <EmptyComponent />}
                            </span>
                            {['Opex', 'Depreciation'].includes(props?.prefix) 
                                && intiData?.total?.children 
                                && intiData?.total?.children?.length > 0 
                                && (
                                    <div className="child-wrap">
                                        {intiData.total.children.map((d, i) => (
                                            <p key={`child-td-${i}`} className="child-item">
                                                <span>{d?.val ? d?.val : <EmptyComponent />}</span>
                                            </p>
                                        ))}
                                    </div>
                                )}
                        </>) : <EmptyComponent />}
                    </td>
                </>
            }
        </>
    )
}

export default RenderTD