import React, { useState } from "react";
import ConfirmOtpForm from "./ConfirmOtpForm";
import TelForm from "./TelForm";
import middlewares from "../../middlewares";
import Username from "./Username";
import Loading from "../../components/Loading";

export const Page = {
  tel: "tel",
  confirm: "confirm",
  username: "username",
};

const Login = props => {
  const [page, setPage] = useState(Page.username);
  const [refOtp, setRefOtp] = useState("");
  const [telno, setTelno] = useState("");
  const [auth, setAuth] = useState(null);
  const [loading, setLoading] = useState(false);

  const _pageSwitch = () => {
    const data = {
      refOtp,
      setPage,
      setRefOtp,
      user: props.user,
      auth: auth,
      setAuth: setAuth,
      telno,
      setTelno,
      loading,
      setLoading,
    };

    switch (page) {
      case Page.tel:
        return <TelForm {...data} />;
      case Page.confirm:
        return <ConfirmOtpForm {...data} />;
      case Page.username:
        return <Username {...data} />;
      default:
        return null;
    }
  };

  return (
    <>
      {_pageSwitch()}
      <Loading show={loading} />
    </>
  );
};

export default middlewares.withLogin(process.env.REACT_APP_LIFF_ID_LOGIN, {
  byPassProfile: true,
})(Login);
