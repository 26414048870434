import React from "react";

interface hasPermissionProps {
  employeeCode: string;
  permissionTypes: { slug: string, permission: string[] }[];
  accessEmployeeCodes?: string[];
  accessPermission?: String;
}

const hasPermission = (props: hasPermissionProps) => {
  if (props?.accessEmployeeCodes?.length) {
    return props?.accessEmployeeCodes?.indexOf(props?.employeeCode || "") > -1;
  } else if (typeof props?.accessPermission === "string") {
    const richmenuPermission = props?.permissionTypes?.filter(
      pt => pt.slug === "richmenu"
    );
    if (!richmenuPermission?.length) return false;
    if (richmenuPermission[0].permissions?.length < 1) return false;
    return (
      richmenuPermission[0].permissions.indexOf(props?.accessPermission) > -1
    );
  } else {
    return true;
  }
};

export default hasPermission;
