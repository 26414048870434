import React, { useState, useEffect } from "react";
import Loading from "../components/Loading";
import DenyAccess from "../components/DenyAccess";
import Networks from "../network";
import hasPermission from "./hasPermission";

const liff = window.liff;

interface Optional {
  byPassProfile?: boolean;
  permissionAccess?: {
    message?: string,
    accessEmployeeCodes?: string[],
    accessPermission?: string,
  };
}

const withLogin = (liffId, optional: Optional) => (Wrapped) => (props) => {
  const is_by_pass =
    process?.env?.REACT_APP_BY_PASS_LINE === "YES" ? true : false;
  if (is_by_pass) {
    return <Wrapped {...props} />;
  } else {
    const [hasLogin, setHasLogin] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState({
      id: null,
      employee_code: "",
      name: "",
      type: {
        id: null,
        name: "",
      },
      permission_types: [],
    });
    const [lineId, setLineId] = useState(null);

    useEffect(() => {
      liff.init({ liffId: liffId }, async (data) => {
        try {
          let profile = await liff.getProfile();
          setLineId(profile.userId);

          if (optional?.byPassProfile) {
            setHasLogin(!!profile.userId);
          } else {
            let res = await Networks.getMemberProfile(profile.userId);
            const _user = {
              ...user,
              id: res.data.id,
              employee_code: res?.data?.employee_code,
              name: res?.data?.name,
              type: res?.data?.type,
              permission_types: res?.data?.permission_types || [],
            };
            setUser(_user);
            setHasLogin(
              !!profile.userId && res.statusOk && res.data.id !== null
            );
          }
        } catch {}
        setLoading(false);
      });
    }, []);

    return loading ? (
      <Loading backdropColor="#fff" opacity={1} show={true} variant="primary" />
    ) : hasLogin &&
      hasPermission({
        employeeCode: user.employee_code,
        permissionTypes: user.permission_types,
        ...optional?.permissionAccess,
      }) ? (
      <Wrapped {...props} user={{ ...user, lineId }} />
    ) : (
      <DenyAccess
        msg={
          !!user.id &&
          !hasPermission({
            employeeCode: user.employee_code,
            permissionTypes: user.permission_types,
            ...optional?.permissionAccess,
          })
            ? optional?.permissionAccess?.message
            : null
        }
      />
    );
  }
};

export default withLogin;
