const liff = window.liff;

const setCookie = (cname, cvalue, exdays) => {
  let d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

const deleteCookie = (cname) => {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

const checkCookie = (cname) => {
  let val = getCookie(cname);
  if (val != "") {
   return true
  } else {
    return false
  }
}

const utils = {
  sendMessages: async message => {
    if (!liff || typeof liff.sendMessages !== "function") return;
    liff.sendMessages([
      {
        type: "text",
        text: message,
      },
    ]);
  },
  closeApp: message => {
    if (
      !liff ||
      typeof liff.sendMessages !== "function" ||
      typeof liff.closeWindow !== "function"
    )
      return;

    if (!!message) {
      liff
        .sendMessages([
          {
            type: "text",
            text: message,
          },
        ])
        .then(() => {
          liff.closeWindow();
        });
    } else {
      liff.closeWindow();
    }
  },
  getNumberColor: (ebitda, normalNumber) => {
    return Number(("" + ebitda).replace(/,/, "")) > 0
      ? "#11D000"
      : ebitda < 0
      ? "#FF0707"
      : normalNumber || "#212529";
  },
  getBadgeNumberColor: (ebitda, normalNumber) => {
    return Number(("" + ebitda).replace(/,/, "")) > 0
      ? "#11D000"
      : ebitda < 0
      ? "#FF0707"
      : normalNumber || "transparent";
  },
  anyApiError: (response: { statusOk: boolean }[]) => {
    const error = response?.find?.(v => !v.statusOk) || null;
    return error;
  },
  setCookie: setCookie,
  getCookie: getCookie,
  deleteCookie: deleteCookie,
  checkCookie: checkCookie
};

export default utils;
