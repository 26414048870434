import React from "react";
import { Spinner, Modal } from "react-bootstrap";

const Loading = ({
  show = false,
  backdropColor = "#000",
  variant = "light",
  opacity = 0.5,
}) => {
  return (
    <div>
      <style jsx="true">{`
        div.modal-backdrop {
          background-color: ${backdropColor};
        }
        div.modal-backdrop.show {
          opacity: ${opacity};
        }
      `}</style>
      <Modal show={show} centered animation={false} onHide={() => {}}>
        <style jsx="true">{modalStyles}</style>
        <Spinner animation="border" variant={variant} />
      </Modal>
    </div>
  );
};

const modalStyles = `
.modal-content {
  background-color: transparent;
  border: none;
}
div.spinner-border {
  align-self: center;
}
`;

export default Loading;
