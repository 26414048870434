import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import Networks from "../../network";
import "./ProjectTransaction.scss";
import {
  modelProjectDetail,
  modelProjectExpenseTransactions,
} from "./viewModel";
import { useParams, useLocation, useHistory } from "react-router-dom";
import utils from "../../utils";
import DetailError from "../../components/DetailError";

const ProjectTransaction = props => {
  const { projectId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [projectName, setProjectName] = useState("");
  const [transactions, setTransactions] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let subscribe = { mounted: true };
    _fetchData(subscribe);
    return () => {
      subscribe.mounted = false;
    };
  }, []);

  const _fetchData = subscribe => {
    setLoading(true);
    const yearMonthMode = `?year_month=${query.get("year")}-${query.get(
      "month",
    )}`;
    Promise.all([
      Networks.v2.getProjectDetail(projectId, yearMonthMode),
      Networks.v2.getProjectDetailExpense(query.get("e29proId")),
    ])
      .then(res => {
        if (!subscribe.mounted) return;
        if (!!utils.anyApiError(res)) {
          const _error = utils.anyApiError(res);
          setError(
            "ดึงข้อมูลไม่สำเร็จ code: " + (_error?.statusCode || "Unknown"),
          );
        } else {
          setError(null);
        }

        if (!!res[0].data?.project) {
          let projectName = "";
          projectName = res[0].data.project.name || "";
          setProjectName(projectName);
          setProject(modelProjectDetail(res[0].data.project));
        } else {
          setProject(null);
        }

        if (res[1].data?.success && res[1].data.expenses) {
          setTransactions(
            modelProjectExpenseTransactions(
              query.get("date"),
              query.get("expense"),
              res[1].data,
            ),
          );
        } else {
          setTransactions(null);
        }
        setLoading(false);
      })
      .catch(() => {
        if (!subscribe.mounted) return;
        setLoading(false);
        setProject(null);
        setTransactions(null);
      });
  };

  const _renderHeader = () => {
    return (
      <div className="header">
        <div className="business-name">{projectName}</div>
        <div className="project-daterange mb-1">
          {project
            ? `ระยะเวลา ${project.start_date_format} - ${project.end_date_format}`
            : ""}
        </div>
        <div className="project-daterange">{transactions?.name || "-"}</div>
      </div>
    );
  };

  const _renderRevenueDetail = () => {
    if (loading || !project || !!error) return null;
    return (
      <div className="queue-detail">
        {transactions?.items?.map((v, i) => (
          <div key={i} className="label-data">
            <div className="d-flex">
              <div>{i + 1}</div>
              <div className="label-name">{v.name}</div>
            </div>
            <span className="label-unit">{v.amount}</span>
          </div>
        ))}
      </div>
    );
  };

  const _renderTotal = () => {
    if (loading || !!error) return null;
    return (
      <>
        <div className="expense-total-data">
          <div className="mr-3">ค่าใช้จ่ายรวม</div>
          <div className="">{transactions?.total || "-"}</div>
        </div>
        <div className="expense-total-data">
          <div className="click" onClick={() => history.goBack()}>
            ปิด
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="page-container revenue-page detail">
      {_renderHeader()}
      {_renderRevenueDetail()}
      {_renderTotal()}
      <DetailError show={!!error} message={error} />
      <Loading show={loading} />
    </div>
  );
};

export default ProjectTransaction;
