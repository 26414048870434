import React, { useEffect, useState } from "react";
import Networks from "../../network";
import "./index.scss";
import {
  parseMonth,
  parseYear
} from "./viewModel";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import TableErpRevenue from "./component-parts/TableErpRevenue";
import _ from "lodash"
import utils from "../../utils";

const RevenueDashboard = props => {
  const { CSID } = useParams();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(false);
  const [titleHead, setTitleHead] = useState(null)
  const [date, setDate] = useState(
    query.get("year") && query.get("month")
      ? moment(
          `${query.get("year")} ${query.get("month")} 01`,
          "YYYY MM DD",
        ).toDate()
      : new Date(),
  );

  const [dateDisplay, setDateDisplay] = useState(
    query.get("year") && query.get("month")
      ? moment(
          `${query.get("year")} ${query.get("month")} 01`,
          "YYYY MM DD",
        ).toDate()
      : new Date(),
  );

  const [erpRevenue, setErpRevenue] = useState(null)

  useEffect(() => {
    let subscribe = { mounted: true };
    setLoading(true);
    if (utils.checkCookie('pnv_group_head')) {
      let val = utils.getCookie('pnv_group_head')
      setTitleHead(val)
    }
    _fetchLastestRevenue(
      date,
      subscribe,
      !(query.get("year") && query.get("month")),
    );
    return () => {
      subscribe.mounted = false;
    };
  }, [date]);

  const getQueryApi = () => {
    const month = parseMonth(date);
    const year = parseYear(date);
    const query = `?year_month=${year}-${month.no}`;
    return query
}

  const _fetchLastestRevenue = (date, subscribe, first) => {
    Networks.v2
        .getErpRevenueMonthlyDetail(CSID, getQueryApi())
        .then(res => {
          if (!subscribe.mounted) return;
          if (res?.statusOk && res?.data?.length > 0) {
            const { data } = res
            let headers = data.map(d => ({
              CSID: d?.CSID, 
              title: d?.title,
              hasLink: d?.children ? true : false
            }))
            headers.push({
              CSID: null,
              title: 'Total'
            })
            let columns = data.map(d => d.dataGL)
            setErpRevenue(() => ({
              headers,
              columns
            }))
          } else {
            setErpRevenue(null);
          }
          
          setLoading(false);
        })
        .catch(() => {
          if (!subscribe.mounted) return;
          setErpRevenue(null);
          setLoading(false);
        });
  };

  const _prevMonth = () => {
    const pd = moment(dateDisplay).add(-1, "months").toDate();
    const month = parseMonth(pd);
    const year = parseYear(pd);
    history.push(`${location.pathname}?year=${year}&month=${month?.no}`);
    setDate(moment(dateDisplay).add(-1, "months").toDate());
    setDateDisplay(moment(dateDisplay).add(-1, "months").toDate());
  };

  const _nextMonth = () => {
    const nd = moment(dateDisplay).add(1, "months").toDate();
    const month = parseMonth(nd);
    const year = parseYear(nd);
    history.push(`${location.pathname}?year=${year}&month=${month?.no}`);
    setDate(moment(dateDisplay).add(1, "months").toDate());
    setDateDisplay(moment(dateDisplay).add(1, "months").toDate());
  };

  const _renderDatePicker = () => {
    return (
      <div className="datepicker-container">
        <div className="btn-prev-next-month" onClick={_prevMonth}>
          <FontAwesomeIcon
            style={{ width: 24, height: 24 }}
            icon="chevron-left"
          />
        </div>
        <div className="date-yearmonth">
          {moment(dateDisplay).format("MMM YYYY")}
        </div>
        <div className="btn-prev-next-month" onClick={_nextMonth}>
          <FontAwesomeIcon
            style={{ width: 24, height: 24 }}
            icon="chevron-right"
          />
        </div>
      </div>
    );
  };

  const _renderErpRevenue = () => {
    if (loading || !erpRevenue) return null;
    return (
      <>
        <TableErpRevenue data={erpRevenue} mode="detail"/>
      </>
    ) 
  }

  const getQueryParams = () => {
      const month = parseMonth(date);
      const year = parseYear(date);
      const query = `?year=${year}&month=${month.no}`;
      return query
  }

  const CustomLink = React.forwardRef((props, ref) => {
    return (
      <a ref={ref} href={props.href} style={{
        background: 'transparent',
        border: 'none',
        borderBottom: '1px solid #000000',
        marginTop: '1rem',
        color: '#000000',
        fontSize: '18px',
        textDecoration: 'none'
      }}>{props.children}</a>
    )
  })

  const _renderNotFound = () => {
    if (!loading && !erpRevenue) {
      return (
        <div className="revenue-report-error" style={{
          flexDirection: 'column'
        }}>
          <div className="text-center" style={{color: '#000'}}>ไม่มีข้อมูลของเดือนนี้</div>
          <div style={{marginTop: '1rem'}}>
            <Link to={`/revenue-report${getQueryParams()}`} component={CustomLink}>
                กลับไปหน้าแรก
            </Link>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="page-container revenue-page dashboard">
      <div className="header">
        <h5 className="mb-1" style={{fontSize: '16px'}}>Pinvest Group : <strong>{titleHead ? titleHead : null}</strong></h5>
        {_renderDatePicker()}
      </div>
      <Container
        style={{
          height: "100vh",
          overflowY: "scroll",
        }}>
        {_renderErpRevenue()}
        {_renderNotFound()}
      </Container>
      <Loading show={loading} />
    </div>
  );
};

export default RevenueDashboard;
