import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import Networks from "../../network";
import "./index.scss";
import {
  modelProjectDetail,
  modelProjectLineChartV2,
  modelProjectExpense,
  fixed,
} from "./viewModel";
import { useParams, useLocation, useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chart from "react-apexcharts";
import moment from "moment";
import utils from "../../utils";
import ProductionCost from "./ProductionCost";
import DetailError from "../../components/DetailError";

const ProjectDetail = props => {
  const { projectId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const yearMonth = moment(
    `${query.get("year")} ${query.get("month")}`,
    "YYYY MM",
  )
    .startOf("month")
    .toDate();

  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [lineChart, setLineChart] = useState(null);
  const [date, setDate] = useState(yearMonth);
  const [projectName, setProjectName] = useState("");
  const [projectExpenses, setProjectExpenses] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    let subscribe = { mounted: true };
    setDate(yearMonth);
    _fetchData(
      moment(yearMonth).format("MM"),
      moment(yearMonth).format("YYYY"),
      subscribe,
    );
    return () => {
      subscribe.mounted = false;
    };
  }, [query.get("year"), query.get("month")]);

  const _fetchData = (month, year, subscribe) => {
    setLoading(true);
    const yearMonthMode = `?year_month=${year}-${month}`;
    Promise.all([
      Networks.v2.getProjectDetail(projectId, yearMonthMode),
      Networks.v2.getProjectDetailExpense(query.get("e29proId")),
    ])
      .then(res => {
        if (!subscribe.mounted) return;
        if (!!utils.anyApiError(res)) {
          const _error = utils.anyApiError(res);
          setError(
            "ดึงข้อมูลไม่สำเร็จ code: " + (_error?.statusCode || "Unknown"),
          );
        } else {
          setError(null);
        }

        if (!!res[0].data?.project) {
          let projectName = "";
          projectName = res[0].data.project.name || "";
          setProjectName(projectName);
          setProject(
            modelProjectDetail(
              res[0].data.project,
              res[0].data.expenses,
              `${year}-${month}`,
            ),
          );
          setLineChart(
            modelProjectLineChartV2(
              res[0].data.project.revenue || 0,
              res[0].data.project.start_date,
              res[0].data.project.end_date,
              res[0].data.project_revenues,
            ),
          );
        } else {
          setProject(null);
          setLineChart(null);
        }

        if (res[1].data?.success && res[1].data.expenses) {
          setProjectExpenses(
            modelProjectExpense(`${year}-${month}`, res[1].data),
          );
        } else {
          setProjectExpenses(null);
        }
        setLoading(false);
      })
      .catch(e => {
        if (!subscribe.mounted) return;
        setLoading(false);
        setProject(null);
        setLineChart(null);
        setProjectExpenses(null);
      });
  };

  const _handleChange = e => {
    history.replace(
      `${location.pathname}?e29proId=${query.get("e29proId")}&year=${e.format(
        "YYYY",
      )}&month=${e.format("MM")}`,
    );
  };

  const _prevMonth = () => {
    _handleChange(moment(date).add(-1, "months"));
  };

  const _nextMonth = () => {
    _handleChange(moment(date).add(1, "months"));
  };

  const _isDateBetweenStartEnd = (
    range: "after" | "before" | "between" = "both",
  ) => {
    if (!project?.id) return false;
    switch (range) {
      case "after":
        return moment(date).isAfter(moment(project.start_date), "month");
      case "before":
        return moment(date).isBefore(moment(project.end_date), "month");
      case "between":
      default:
        return moment(date).isBetween(
          moment(project.start_date),
          moment(project.end_date),
          "month",
          "()",
        );
    }
  };

  const _renderDatePicker = () => {
    const canPrev = _isDateBetweenStartEnd("after");
    const canNext = _isDateBetweenStartEnd("before");
    return (
      <div className="datepicker-container">
        <div
          className="btn-prev-next-month"
          onClick={canPrev ? _prevMonth : undefined}>
          <FontAwesomeIcon
            style={{
              width: 24,
              height: 24,
              color: canPrev ? "#fff" : "#dddddd3f",
            }}
            icon="chevron-left"
          />
        </div>
        <div style={{ color: "#ffffff" }} className="date-yearmonth">
          {moment(date).format("MMM YYYY")}
        </div>
        <div
          className="btn-prev-next-month"
          onClick={canNext ? _nextMonth : undefined}>
          <FontAwesomeIcon
            style={{
              width: 24,
              height: 24,
              color: canNext ? "#fff" : "#dddddd3f",
            }}
            icon="chevron-right"
          />
        </div>
      </div>
    );
  };

  const _renderHeader = () => {
    return (
      <div className="header">
        <div className="business-name">{projectName}</div>
        <div className="project-daterange mb-1">
          {project
            ? `ระยะเวลา ${project.start_date_format} - ${project.end_date_format}`
            : ""}
        </div>
        {_renderDatePicker()}
      </div>
    );
  };

  const _renderLineChart = () => {
    if ((loading && !lineChart) || !!error) return null;

    return (
      <Chart
        options={{
          noData: {
            text: "ไม่มีข้อมูล",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#115fa7",
              fontSize: "1.5rem",
              fontFamily: undefined,
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: 2,
            curve: "smooth",
          },
          labels: lineChart?.dates || [],
          xaxis: {
            type: "datetime",
          },
          yaxis: {
            title: {
              text: "(ล้าน)",
            },
          },
          tooltip: {
            enabled: false,
          },
        }}
        series={lineChart?.series || []}
        width={Math.min(window.screen.width, 512)}
        type="line"
      />
    );
  };

  const _renderRevenueDetail = () => {
    if (loading || !project || !projectExpenses || !!error) return null;
    return (
      <div className="revenue-detail">
        <div className="group">
          <div className="label-group">
            <span className="label-name">{project.revenue.name}</span>
            <span className="label-unit">{project.revenue.unit}</span>
          </div>
        </div>
        <div className="group">
          <div className="label-group">
            <span className="label-name">{project.cumulative_cost.name}</span>
            <span className="label-unit">
              {fixed(
                project.cumulative_cost.amount +
                  (projectExpenses?.total_cost || 0),
              )}
            </span>
          </div>
          <div className="list-label-data">
            <ProductionCost
              name={project.cumulative_pre.name}
              unit={fixed(
                project.cumulative_pre.amount +
                  (projectExpenses?.total_pre || 0),
              )}
              expenses={[
                ...(projectExpenses?.pre || []),
                ...(project.expenses?.pre || []),
              ]}
            />
            <ProductionCost
              name={project.cumulative_pro.name}
              unit={fixed(
                project.cumulative_pro.amount +
                  (projectExpenses?.total_pro || 0),
              )}
              expenses={[
                ...(projectExpenses?.pro || []),
                ...(project.expenses?.pro || []),
              ]}
            />
            <ProductionCost
              name={project.cumulative_post.name}
              unit={fixed(
                project.cumulative_post.amount +
                  (projectExpenses?.total_post || 0),
              )}
              expenses={[
                ...(projectExpenses?.post || []),
                ...(project.expenses?.post || []),
              ]}
            />
          </div>
        </div>
        <div className="group line">
          <div className="label-group">
            <span className="label-name">{project.gross_profit.name}</span>
            <span
              className="label-unit"
              style={{
                color: utils.getNumberColor(
                  fixed(
                    project.gross_profit.amount -
                      (projectExpenses?.total_cost || 0),
                  ),
                  "#115fa7",
                ),
              }}>
              {fixed(
                project.gross_profit.amount -
                  (projectExpenses?.total_cost || 0),
              )}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="page-container revenue-page detail">
      {_renderHeader()}
      <div
        style={{
          height: "100vh",
          overflowY: "scroll",
        }}>
        {_renderLineChart()}
        {_renderRevenueDetail()}
        <DetailError show={!!error} message={error} />
      </div>
      <Loading show={loading} />
    </div>
  );
};

export default ProjectDetail;
