import React, { useState } from "react";
import { Form, Container, Spinner, Alert } from "react-bootstrap";
import Networks from "../../network";
import { Page } from ".";
import "./index.scss";
import FormValidate from "../../components/FormValidate";

const TelForm = props => {
  const [tel, setTel] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const _onClick = e => {
    e.preventDefault();
    setError("");
    setLoading(true);
    Networks.sendOptWithToken(tel, props.auth?.access_token)
      .then(res => {
        setLoading(false);
        if (res.statusOk && !!res.data.code) {
          props.setTelno(tel);
          props.setRefOtp(res.data.code);
          props.setPage(Page.confirm);
        } else if (res.statusCode === 422) {
          setError("เบอร์นี้ถูกใช้งานแล้ว");
        } else {
          setError(
            "ส่ง OTP ล้มเหลว " +
              (res.statusCode ? ` code: ${res.statusCode}` : "")
          );
        }
      })
      .catch(e => {
        setLoading(false);
        setError("เกิดข้อผิดพลาด " + e);
      });
  };

  const _onChangeText = (name, value) => {
    setTel(value);
  };

  return (
    <div className="page-container">
      <Container>
        <Form className="form-content">
          <h1 className="title-header">เข้าสู่ระบบ</h1>
          <div style={{ fontSize: "0.8rem", padding: "1rem" }}></div>
          <Alert
            style={{ fontSize: "0.8rem" }}
            variant={!!error ? "danger" : "primary"}
          >
            {error || "โปรดระบุเบอร์โทร เพื่อรับรหัสยืนยัน"}
          </Alert>
          <FormValidate
            validates={[
              {
                rule: v => v.trim() !== "",
                msg: "Required",
              },
              {
                rule: v => !!v.match(/^0[0-9]{9}$/),
                msg: "โปรดใส่หมายเลขโทรศัพท์ให้ถูกต้อง",
              },
            ]}
            initForm={{ tel: "" }}
            onKeyValueChange={_onChangeText}
            onSubmit={_onClick}
          >
            {({ values, errors, onChangeText, onBlur, onSubmit }) => (
              <>
                <Form.Group>
                  <Form.Control
                    className={
                      "none-outline" + (!!errors.tel ? " form-input-error" : "")
                    }
                    disabled={loading}
                    name="tel"
                    value={values.tel}
                    type="tel"
                    placeholder="หมายเลขโทรศัพท์"
                    maxLength={10}
                    onChange={onChangeText}
                    onBlur={onBlur}
                  />
                  {!!errors.tel && (
                    <div className="form-error">{errors.tel}</div>
                  )}
                </Form.Group>
                <button
                  className="btn btn-custom mb-3"
                  disabled={loading}
                  onClick={onSubmit}
                >
                  {loading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {" เข้าสู่ระบบ"}
                </button>
              </>
            )}
          </FormValidate>
        </Form>
      </Container>
    </div>
  );
};

export default TelForm;
