import React, { useEffect, useState } from "react";
import Loading from "../../components/Loading";
import Networks from "../../network";
import "./index.scss";
import {
  modelRevenueDetail,
  modelLineChart,
  modelRevenueDetailV2,
  modelLineChartV2,
  isDateMyAccount,
  KeyCategories,
} from "./viewModel";
import { useParams, useLocation, useHistory } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Chart from "react-apexcharts";
import moment from "moment";
import utils from "../../utils";
import DetailError from "../../components/DetailError";
import TableInfiniteKitchen from "./component-parts/TableInfiniteKitchen";
import TableLuxury29 from "./component-parts/TableLuxury29";

const RevenueDetail = props => {
  const { businessId } = useParams();
  const location = useLocation();
  const history = useHistory();

  const query = new URLSearchParams(location.search);
  const yearMonth = moment(
    `${query.get("year")} ${query.get("month")}`,
    "YYYY MM",
  )
    .startOf("month")
    .toDate();

  const [loading, setLoading] = useState(false);
  const [business, setBusiness] = useState(null);
  const [lineChart, setLineChart] = useState(null);
  const [date, setDate] = useState(yearMonth);
  const [businessName, setBusinessName] = useState("");
  const isInfinitekitchen = !!Number(query.get("isInktc") || 0);
  const isLuxury29 = !!Number(query.get("isLuxury29") || 0);
  const [error, setError] = useState(null);

  useEffect(() => {
    let subscribe = { mounted: true };
    setDate(yearMonth);
    _fetchData(
      moment(yearMonth).format("MM"),
      moment(yearMonth).format("YYYY"),
      subscribe,
    );
    return () => {
      subscribe.mounted = false;
    };
  }, [query.get("year"), query.get("month")]);

  const _onFetchError = subscribe => {
    if (!subscribe.mounted) return;
    setLoading(false);
    setBusiness(null);
    setLineChart(null);
  };

  const checkError = res => {
    if (!!utils.anyApiError(res)) {
      const _error = utils.anyApiError(res);
      setError(
        "ดึงข้อมูลรายได้ไม่สำเร็จ code: " + (_error?.statusCode || "Unknown"),
      );
    } else {
      setError(null);
    }
  };

  const _fetchData = (month, year, subscribe) => {
    setLoading(true);
    const type = query.get("type");
    const yearMonthMode = `?year_month=${year}-${month}&mode=year-month`;
    const yearMode = `?year=${year}&mode=year`;
    if (isDateMyAccount(`${year}${month}01`, "YYYYMMDD")) {
      if (type === "pinvestment") {
        Promise.all([
          Networks.v2.getPinvestmentDetail(yearMonthMode),
          Networks.v2.getPinvestmentDetail(yearMode),
        ])
          .then(res => {
            if (!subscribe.mounted) return;
            checkError(res);
            if (!!res[0].data.groups?.length && !!res[0].data.pinvestment) {
              setBusinessName("Pinvestment");
              setBusiness(
                modelRevenueDetailV2({
                  my_account: {
                    total_income: res[0].data.pinvestment.income || 0,
                    total_variable_cost:
                      res[0].data.pinvestment.variable_cost || 0,
                    total_opex: res[0].data.pinvestment.opex || 0,
                  },
                }),
              );
            } else {
              setBusiness(null);
            }
            setLineChart(modelLineChartV2(res[1]?.data));
            setLoading(false);
          })
          .catch(() => _onFetchError(subscribe));
      } else if (type === "group") {
        Promise.all([
          Networks.v2.getBusinessGroupDetail(businessId, yearMonthMode),
          Networks.v2.getBusinessGroupDetail(businessId, yearMode),
        ])
          .then(res => {
            if (!subscribe.mounted) return;
            checkError(res);
            if (!!res[0]?.data) {
              setBusinessName(res[0].data.name);
              setBusiness(
                modelRevenueDetailV2({
                  my_account: {
                    total_income: res[0].data.total_businesses_income || 0,
                    total_variable_cost:
                      res[0].data.total_businesses_variable_cost || 0,
                    total_opex: res[0].data.total_businesses_opex || 0,
                  },
                }),
              );
            } else {
              setBusiness(null);
            }
            setLineChart(modelLineChartV2(res[1]?.data));
            setLoading(false);
          })
          .catch(() => _onFetchError(subscribe));
      } else {
        Promise.all([
          Networks.v2.getBusinessDetail(businessId, yearMonthMode),
          Networks.v2.getBusinessDetail(businessId, yearMode),
        ])
          .then(res => {
            if (!subscribe.mounted) return;
            checkError(res);
            if (!!res?.[0]?.data?.my_account) {
              setBusinessName(res[0].data.name);
              setBusiness(modelRevenueDetailV2(res[0].data));
            } else {
              setBusiness(null);
            }
            setLineChart(modelLineChartV2(res[1]?.data?.my_account?.revenues));
            setLoading(false);
          })
          .catch(() => _onFetchError(subscribe));
      }
    } else {
      Promise.all([
        type === "pinvestment"
          ? Networks.getPinvestmentRevenue(yearMonthMode)
          : type === "group"
          ? Networks.getGroupRevenue(businessId, yearMonthMode)
          : Networks.getRevenue(businessId, yearMonthMode),
        type === "pinvestment"
          ? Networks.getPinvestmentRevenue(yearMode)
          : type === "group"
          ? Networks.getGroupRevenue(businessId, yearMode)
          : Networks.getRevenue(businessId, yearMode),
      ])
        .then(res => {
          if (!subscribe.mounted) return;
          checkError(res);
          if (!!res[0].data && !!res[1].data) {
            let businessName = "";
            let expenseTable = null;
            let expenseGraph = null;
            if (type === "pinvestment") {
              businessName = "Pinvestment";
              expenseTable = { revenue: res[0].data };
              expenseGraph = res[1].data;
            } else if (type === "group") {
              businessName = res[0].data.name || "";
              expenseTable = { revenue: res[0].data.summary };
              expenseGraph = res[1].data.summaries;
            } else {
              businessName = res[0].data.name || "";
              expenseTable = res[0].data;
              expenseGraph = res[1].data.revenues;
            }
            setBusinessName(businessName);
            setBusiness(modelRevenueDetail(expenseTable));
            setLineChart(modelLineChart(expenseGraph));
          } else {
            setBusiness(null);
            setLineChart(null);
          }
          setLoading(false);
        })
        .catch(() => _onFetchError(subscribe));
    }
  };

  const _handleChange = e => {
    query.set("month", e.format("MM"));
    query.set("year", e.format("YYYY"));
    const queries = [];
    query.forEach((v, k) => queries.push(`${k}=${v}`));
    history.replace(`${location.pathname}?${queries.join("&")}`);
  };

  const _prevMonth = () => {
    _handleChange(moment(date).add(-1, "months"));
  };

  const _nextMonth = () => {
    _handleChange(moment(date).add(1, "months"));
  };

  const _renderDatePicker = () => {
    return (
      <div className="datepicker-container">
        <div className="btn-prev-next-month" onClick={_prevMonth}>
          <FontAwesomeIcon
            style={{ width: 24, height: 24, color: "#fff" }}
            icon="chevron-left"
          />
        </div>
        <div style={{ color: "#ffffff" }} className="date-yearmonth">
          {moment(date).format("MMM YYYY")}
        </div>
        <div className="btn-prev-next-month" onClick={_nextMonth}>
          <FontAwesomeIcon
            style={{ width: 24, height: 24, color: "#fff" }}
            icon="chevron-right"
          />
        </div>
      </div>
    );
  };

  const _renderHeader = () => {
    return (
      <div className="header">
        <div className="business-label">Monthly Report:</div>
        <div className="business-name mb-1">{businessName}</div>
        {_renderDatePicker()}
      </div>
    );
  };

  const _renderLineChart = () => {
    if ((loading && !lineChart) || !!error) return null;

    return (
      <Chart
        options={{
          noData: {
            text: "ไม่มีข้อมูล",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#115fa7",
              fontSize: "1.5rem",
              fontFamily: undefined,
            },
          },
          chart: {
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: 2,
            curve: "smooth",
          },
          labels: lineChart?.dates || [],
          xaxis: {
            type: "datetime",
          },
          yaxis: {
            title: {
              text: "(ล้าน)",
            },
          },
          tooltip: {
            enabled: false,
          },
        }}
        series={lineChart?.series || []}
        width={Math.min(window.screen.width, 512)}
        type="line"
      />
    );
  };

  const _renderRevenueDetail = () => {
    if (loading || !business || !!error) return null;
    const isMyAccountData = isDateMyAccount(date);
    const keyOpex = isMyAccountData ? KeyCategories.opex : "opex_business";
    return (
      <div className="revenue-detail mt-3">
        <div className="group">
          <div className="label-group">
            <span className="label-name">{business.revenue.name}</span>
            <span className="label-unit">{business.revenue.unit}</span>
          </div>
          <div className="list-label-data">
            {business.expense_categories
              ?.filter(v => v.type === KeyCategories.revenue)
              ?.map((v, i) => (
                <div key={i} className="label-data">
                  <span className="label-name-category">
                    {v.expense_category.name}
                  </span>
                  <span className="label-unit">{v.unit}</span>
                </div>
              ))}
          </div>
        </div>
        <div className="group">
          <div className="label-group">
            <span className="label-name">{business.variable_cost.name}</span>
            <span className="label-unit">{business.variable_cost.unit}</span>
          </div>
          <div className="list-label-data">
            {business.expense_categories
              ?.filter(v => v.type === KeyCategories.cost)
              ?.map((v, i) => (
                <div key={i} className="label-data">
                  <span className="label-name-category">
                    {v.expense_category.name}
                  </span>
                  <span className="label-unit">{v.unit}</span>
                </div>
              ))}
          </div>
        </div>
        <div className="group line">
          <div className="label-group">
            <span className="label-name">{business.gross_profit.name}</span>
            <span
              className="label-unit"
              style={{
                color: utils.getNumberColor(
                  business.gross_profit.unit,
                  "#115fa7",
                ),
              }}>
              {business.gross_profit.unit}
            </span>
          </div>
        </div>
        {!isMyAccountData && !!business.opex_support && (
          <div className="label-group">
            <span className="label-name">{business.opex_support.name}</span>
            <span className="label-unit">{business.opex_support.unit}</span>
          </div>
        )}
        <div className="group">
          <div className="group">
            <div className="label-group">
              <span className="label-name">{business[keyOpex]?.name}</span>
              <span className="label-unit">{business[keyOpex]?.unit}</span>
            </div>
          </div>
          <div className="list-label-data">
            {business.expense_categories
              ?.filter(v => v.type === "opex")
              ?.map((v, i) => (
                <div key={i} className="label-data">
                  <span className="label-name-category">
                    {v.expense_category.name}
                  </span>
                  <span className="label-unit">{v.unit}</span>
                </div>
              ))}
          </div>
        </div>
        {!isMyAccountData && !!business.opex && (
          <div className="group line">
            <div className="label-group">
              <span className="label-name">{business.opex.name}</span>
              <span className="label-unit">{business.opex.unit}</span>
            </div>
          </div>
        )}
        <div className="group">
          <div className="label-group">
            <span className="label-name">{business.ebitda.name}</span>
            <span
              className="label-unit"
              style={{
                color: utils.getNumberColor(business.ebitda.unit, "#115fa7"),
              }}>
              {business.ebitda.unit}
            </span>
          </div>
        </div>
      </div>
    );
  };

  const _renderTable = () => {
    if (loading || !business || !!error) return null;
    if (!business || query.get("type") !== "business" || loading || !!error) return null;
    return (<TableInfiniteKitchen version="new"/>);
  };

  const _renderLuxury29Table = () => {
    if (loading || !business || !!error) return null;
    if (!business || query.get("type") !== "business" || loading || !!error) return null;
    return (<TableLuxury29 />);
  };

  return (
    <div className="page-container revenue-page detail">
      {_renderHeader()}
      <div
        style={{
          height: "100vh",
          overflowY: "scroll",
        }}>
        {_renderLineChart()}
        {_renderRevenueDetail()}
        {isInfinitekitchen && _renderTable()}
        {isLuxury29 && _renderLuxury29Table()}
        <DetailError show={!!error} message={error} />
      </div>
      <Loading show={loading} />
    </div>
  );
};

export default RevenueDetail;
