import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useLocation, useHistory, useParams, Link } from 'react-router-dom'
import moment from 'moment';
import { fixed, numberFormat, parseMonth, parseYear } from '../viewModel';
import utils from '../../../utils'
import TableErpRevenueStyle from './TableErpRevenueStyle';
import RenderTD from './RenderTD';

const TableErpRevenue = (props) => {
    const location = useLocation();
    const history = useHistory();
    const params = useParams()
    const query = new URLSearchParams(location.search);
    const [loading, setLoading] = useState(true)
    const [intiData, setIntiData] = useState(props?.data || {})
    const [tableItems, setTableItems] = useState([])
    const [date, setDate] = useState(
        query.get("year") && query.get("month")
          ? moment(
              `${query.get("year")} ${query.get("month")} 01`,
              "YYYY MM DD",
            ).toDate()
          : new Date(),
      );
    useEffect(() => {
        (async () => {
            let datas = intiData.columns
            let leftColumn = []
            let total = []
            for await (let data of datas) {
                for await (let d of data) {
                    let obj = {
                        columnName: d.GLAccName,
                        hasChild: false,
                        childColumn: []
                    }
                    if (['Opex', 'Depreciation'].includes(d.GLAccName) && d?.children && d?.children?.length > 0) {
                        obj = {...obj, hasChild: true, childColumn: d.children.map(d => d.GLAccName)}
                    }
                    leftColumn.push(obj)
                }
            }
            leftColumn = _.uniqBy(leftColumn, 'columnName')
            for await (let col of leftColumn) {
                let obj = {
                    col: col.columnName,
                    val: 0,
                    child_val_items: []
                }
                let vals = intiData.columns.map(column => {
                    let d = column.find(d => d.GLAccName === col.columnName)
                    return d.val
                })
                obj.val = _.sum(vals)
                if (col.hasChild) {
                    for await (let childCol of col.childColumn) {
                        let child_vals = intiData.columns.map(column => {
                            let tmp = column.find(d => d.GLAccName === col.columnName)
                            let d = tmp.children.find(b => b.GLAccName === childCol)
                            return d.val
                        })
                        obj.child_val_items.push(_.sum(child_vals))
                    }
                }
                total.push(obj)
            }
            let item = {...intiData, total}
            setIntiData(d => ({...item}))
            if (location.pathname === '/revenue-report') {
                if (utils.checkCookie('pnv_group_head')) {
                    utils.deleteCookie('pnv_group_head')
                }
            }
            const resultTableItems = intiTDComponent(item)
            setTableItems(resultTableItems)
            setLoading(false)
        })()
    }, [])

    const getQueryParams = () => {
        const month = parseMonth(date);
        const year = parseYear(date);
        const query = `?year=${year}&month=${month.no}`;
        return query
    }

    const intiTDComponent = (objData) => {
        let datas = [
            {
                main: 'Employee',
                children: []
            }, 
            {
                main: 'Temporary',
                children: []
            }, 
            {
                main: 'Revenue',
                children: []
            }, 
            {
                main: 'COGs',
                children: []
            }, 
            {
                main: 'Margin',
                children: []
            }, 
            {
                main: 'Opex',
                children: [
                    'Salary',
                    'Bonus',
                    'Compensate',
                    'Temporary Wages',
                    'Welfare',
                    'Marketing',
                    'Youth Sport School',
                    'Other'
                ]
            }, 
            {
                main: 'EBITDA',
                children: []
            }, 
            {
                main: 'Depreciation',
                children: [
                    'Asset',
                    'Intangible assets'
                ]
            },
            {
                main: 'EBIT',
                children: []
            },
            {
                main: 'Net Profit',
                children: []
            },
        ]
        let results = [] 
        if (objData?.columns && objData?.columns?.length > 0) {
            datas.map((data, index) => {
                let components = []
                let key = data.main
                let isNumber = false
                let isSpecialStyle = false
                let fontRed = ['EBITDA', 'EBIT', 'Net Profit'].includes(key) ? true : false
                if (['Employee', 'Temporary'].includes(key)) {
                    isNumber = true
                    isSpecialStyle = true
                }
                components = objData.columns.map((column, i) => {
                    let data = column.find(c => c.GLAccName === key)
                    if (data) {
                        let item = {
                            main: data.GLAccName,
                            children: []
                        }
                        let val = null 
                        if (fontRed) {
                            val = data?.val ? fixed(data?.val, isNumber ? 0 : 2) : null
                        } else {
                            val = data?.val ? fixed(data?.val) : null
                            if (isNumber) {
                                val = data?.val ? numberFormat(data?.val, isNumber) : null
                            }
                        }
    
                        item = {...item, fontRed, isSpecialStyle, isNumber, val}
                        if (['Opex', 'Depreciation'].includes(data.GLAccName) && data?.children && data?.children?.length > 0 ) {
                            let children = data.children.map((child, i) => {
                                let val = child?.val ? fixed(child?.val) : null
                                return {
                                    main: child.GLAccName, 
                                    val
                                }
                            })
                            item = {...item, children}
                        }
                        return item
                    }
                })
                results[key] = {
                    main: key,
                    items: components
                }
            })
        }
        if (objData?.total && objData?.total?.length > 0) {
            datas.map((data, index) => {
                let key = data.main
                let item = {
                    main: key,
                    children: []
                }
                let isNumber = false
                let isSpecialStyle = false
                let fontRed = ['EBITDA', 'EBIT', 'Net Profit'].includes(key) ? true : false
                if (['Employee', 'Temporary'].includes(key)) {
                    isNumber = true
                    isSpecialStyle = true
                }
                const total = objData?.total?.find(t => t.col === key)
                let val = null 
                if (fontRed) {
                    val = total?.val ? fixed(total.val) : fixed(0)
                } else {
                    val = total?.val ? fixed(total?.val) : fixed(0)
                    if (isNumber) {
                        val = total?.val ? numberFormat(total?.val, isNumber) : fixed(0, 0)
                    }
                }
                item = {...item, fontRed, isSpecialStyle, isNumber, val}
                if (['Opex', 'Depreciation'].includes(key) && total?.child_val_items && total?.child_val_items?.length > 0 ) {
                    let children = total.child_val_items.map((v, i) => {
                        let val = v ? fixed(v) : fixed(0)
                        return {
                            main: null, 
                            val
                        }
                    })
                    item = {...item, children}
                }
                results[key] = {...results[key], total: item}
            })
        }

        return results
    }

    const _renderTH = (string, childs = []) => {
        return (
            <>
                <th className="fixed-side-left">
                    <span className="text-left-column">{string === 'Empty' ? <>&nbsp;</> : string}</span>
                    {childs.length > 0 && (
                        <>
                            <div className="child-wrap">
                                {childs.map((child, i) => (
                                    <p key={`child-th-${i}`} className="child-item">{child}</p>
                                ))}
                            </div>
                        </>
                    )}
                </th>
            </>
        )
    }

    const setCookieHeader = (title) => {
        utils.setCookie('pnv_group_head', title, 1)
    }

    const CustomLink = React.forwardRef((props, ref) => {
        return (<a className="text-a-top-column" ref={ref} href={props.href} onClick={() => setCookieHeader(props.title)}>{props.children}</a>)
    })

    const backToMain = () => {
        history.replace(`/revenue-report${getQueryParams()}`)
    }

    return (
        <>
            {!loading && 
                <>
                    <div>
                        <span style={{
                            fontWeight: 600
                        }}>
                            Monthly PL Report
                        </span>
                    </div>
                    <TableErpRevenueStyle>
                        <div className={`header-table-wrap ${props?.mode === 'detail' ? 'table-wrap-space-between' : 'mb-0'}`}>
                            {props?.mode === 'detail' ? (
                                <>
                                    <button className="back-btn" type="button" onClick={() => backToMain()}>หน้าแรก</button>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="table-scroll">
                            <div className="table-wrap">
                                <table>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="fixed-side-top header-top">&nbsp;</th>
                                            {intiData?.headers?.length > 0 && intiData.headers.map((header, i) => (
                                                <th key={`header-${i}`} scope="col" className={`header-top-wrap ${header.title === 'Total' ? 'header-top-total-wrap' : ''}`}>
                                                    {header.hasLink ? 
                                                        <Link to={`/revenue-report/erp-revenue/${header.CSID}${getQueryParams()}`} title={header.title} component={CustomLink}>
                                                            {header.title}
                                                        </Link> :
                                                        <a className="text-a-top-column" style={{ textDecoration: 'none' }}>{header.title}</a>
                                                    }
                                                </th>    
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {_renderTH('Employee')}
                                            <RenderTD datas={tableItems['Employee']} prefix={'Employee'}/>
                                        </tr>
                                        <tr>
                                            {_renderTH('Temporary')}
                                            <RenderTD datas={tableItems['Temporary']} prefix={'Temporary'}/>
                                        </tr>
                                        <tr>
                                            {_renderTH('Empty')}
                                            <RenderTD datas={tableItems['Temporary']} prefix={'Empty'}/>
                                        </tr>
                                        <tr>
                                            {_renderTH('Revenue')}
                                            <RenderTD datas={tableItems['Revenue']} prefix={'Revenue'}/>
                                        </tr>
                                        <tr>
                                            {_renderTH('COGS')}
                                            <RenderTD datas={tableItems['COGs']} prefix={'COGs'}/>
                                        </tr>
                                        <tr>
                                            {_renderTH('Margin')}
                                            <RenderTD datas={tableItems['Margin']} prefix={'Margin'}/>
                                        </tr>
                                        <tr>
                                            {_renderTH('Opex', [
                                                'Salary',
                                                'Bonus',
                                                'Compensate',
                                                'Temporary Wages',
                                                'Welfare',
                                                'Marketing',
                                                'Youth Sport School',
                                                'Other',
                                            ])}
                                            <RenderTD datas={tableItems['Opex']} prefix={'Opex'}/>
                                        </tr>
                                        <tr>
                                            {_renderTH('EBITDA')}
                                            <RenderTD datas={tableItems['EBITDA']} prefix={'EBITDA'}/>
                                        </tr>
                                        <tr>
                                            {_renderTH('Depreciation', [
                                                'Asset',
                                                'Intangible assets'
                                            ])}
                                            <RenderTD datas={tableItems['Depreciation']} prefix={'Depreciation'}/>
                                        </tr>
                                        <tr>
                                            {_renderTH('EBIT')}
                                            <RenderTD datas={tableItems['EBIT']} prefix={'EBIT'}/>
                                        </tr>
                                    </tbody>
                                    <tfoot className="table-footer">
                                        <tr>
                                            {_renderTH('Net Profit')}
                                            <RenderTD datas={tableItems['Net Profit']} prefix={'Net Profit'}/>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </TableErpRevenueStyle>
                </>
            }
        </>
    )
}

export default TableErpRevenue