import React from 'react';
import Styled from "styled-components";

const Style = Styled.div`
    margin: 1rem 0 2rem 0;
    .fixed-side-top {
        min-width: 115px;
        position: sticky;
        left: 0;
        background-color: #115fa7;
        border-left: 1px solid #495057;
        border-right: 1px solid #495057;
    }
    .fixed-side-left {
        min-width: 115px;
        position: sticky;
        left: 0;
        background:#fff;
        border-right: 1px solid #495057;
        border-left: 1px solid #495057;
        font-size: 12px;
    }
    .header-top-total-wrap {
        background-color: #FFFD38 !important;
        color: #dc3545 !important;
    }
    .header-top-total-wrap > .text-a-top-column {
        color: #dc3545 !important;
        text-decoration: none !important;
    }
    .header-top-wrap > .text-a-top-column {
        color: #ffffff;
        font-weight: bold;
        text-decoration: underline;
        background-color: transparent;
    }
    .header-top-wrap {
        min-width: 130px;
        border-top: 1px solid #495057;
        border-bottom: 1px solid #495057;
        border-right: 1px solid #495057;
        text-align: center;
        color: #ffffff;
        background-color: #115fa7;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }
    .table-scroll {
        position:relative;
        max-width: 420px;
        margin:auto;
        overflow:hidden;
    }
    .table-wrap {
        width:100%;
        overflow:auto;
    }
    .table-scroll table {
        width:100%;
        margin:auto;
        border-collapse: separate;
        border-spacing: 0;
    }
    
    .table-scroll td {
        padding: 2px 4px; 
        background:#fff;
        white-space:nowrap;
        vertical-align:top;
        border-right: 1px solid #495057;
        font-size: 12px;
        text-align: right;
    }
    .table-scroll th {
        padding: 2px 4px;
        white-space:nowrap;
        vertical-align:top;
        font-size: 12px;
    }
    .table-scroll thead, .table-scroll tfoot {
        background:#f9f9f9;
    }
    .table-footer td, .table-footer th {
        border-top: 1px solid #495057;
        border-bottom: 1px solid #495057;
    }
    .child-item {
        margin-bottom: 0;
        padding-left: .4rem;
        font-weight: lighter;
        color: #5c5c5c;
    }

    table > tbody > tr > td:last-child, .table-footer td:last-child {
        background-color: #FFF1CF !important;
    }
    .header-table-wrap {
        margin-bottom: 1rem;
        display: flex;
        justify-content: flex-start;
    }
    .table-wrap-space-between {
        justify-content: space-between !important;
    }
    .header-table-wrap > .back-btn {
        border: none;
        background-color: transparent;
        border-bottom: 3px solid #115fa7;
        font-size: 14px;
    }
    .header-table-wrap > .header-table-title {
        margin-bottom: 0;
        font-weight: bold;
    }
    .text-center-style {
        display: block;
        font-style: italic;
        text-align: center;
    }
`;
const TableErpRevenueStyle = (props) => {
   return (
        <>
           <Style>{props.children}</Style>
        </>
    )
}

export default TableErpRevenueStyle