import React, { useEffect } from "react";
import middlewares from "../../middlewares";
import RevenueDashboard from "./RevenueDashboard";
import RevenueDetail from "./RevenueDetail";
import ProjectTransaction from "./ProjectTransaction";
import ProjectDetail from "./ProjectDetail";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import InfiniteKitchenDetail from "./InfiniteKitchenDetail";
import ErpRevenueDetail from "./ErpRevenueDetail"

const RevenueReport = props => {
  useEffect(() => _init(), []);

  const _init = () => {
    document.title = "Pinvestment Report";
  };

  return (
    <div>
      <Router>
        <Switch>
          <Route
            exact
            path={props.match.path}
            component={p => <RevenueDashboard {...p} user={props.user} />}
          />
          <Route
            path={`${props.match.path}/business/:businessId`}
            component={p => <RevenueDetail {...p} user={props.user} />}
          />
          <Route
            path={`${props.match.path}/project/:projectId/transaction`}
            component={p => <ProjectTransaction {...p} user={props.user} />}
          />
          <Route
            path={`${props.match.path}/project/:projectId`}
            component={p => <ProjectDetail {...p} user={props.user} />}
          />
          <Route
            path={`${props.match.path}/infinite-kitchen/:businessId`}
            component={p => <InfiniteKitchenDetail {...p} user={props.user} />}
          />
          <Route
            path={`${props.match.path}/erp-revenue/:CSID`}
            component={p => <ErpRevenueDetail {...p} user={props.user} />}
          />
        </Switch>
      </Router>
    </div>
  );
};

export default middlewares.withLogin(
  process.env.REACT_APP_LIFF_ID_REVENUE_REPORT,
  {
    permissionAccess: {
      message: "ข้อมูลรายได้เฉพาะผู้ที่เกี่ยวข้อง",
      accessPermission: "pinvestment-report",
    },
  },
)(RevenueReport);
