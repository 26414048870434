import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/login";
import Logout from "./pages/logout";
import ConfirmBooking from "./pages/confirm-booking";
import { library } from "@fortawesome/fontawesome-svg-core";
import * as SolidSvgIcon from "@fortawesome/free-solid-svg-icons";
import Calendar from "./pages/calendar";
import RevenueReport from "./pages/revenue-report";
import './App.css'

require("moment/locale/th");
require("dotenv").config();

library.add(
  ...Object.keys(SolidSvgIcon)
    .map(k => SolidSvgIcon[k])
    .filter(v => typeof v === "object")
);

function App() {
  return (
    <div
      className="fontSarabun"
      style={{
        minHeight: "100vh"
      }}
    >
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/confirmbooking" component={ConfirmBooking} />
          <Route path="/calendar" component={Calendar} />
          <Route path="/revenue-report" component={RevenueReport} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
