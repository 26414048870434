import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DetailError = ({ show, message = "" }) => {
  if (!show) return null;
  return (
    <div className="error-container">
      <FontAwesomeIcon
        className="error-icon"
        icon="exclamation-circle"
        size="3x"
        color="#535353"
      />
      <div className="error-message">{message || ""}</div>
      <style jsx="true">{`
        .error-container {
          display: flex;
          height: 100%;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 3rem 1rem;
        }
        .error-icon {
          margin-bottom: 1rem;
        }
        .error-message {
          color: #505050;
          font-size: 1.25rem;
        }
      `}</style>
    </div>
  );
};

export default DetailError;
