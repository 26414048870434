import React from 'react'

const DefaultTable = (props) => {
    return (
        <>
            <table className="table">
                <thead>
                    <tr>
                    <th scope="col" style={{
                        paddingLeft: '2px',
                        paddingRight: '2px'
                    }}>วันที่</th>
                    <th scope="col">รายการ</th>
                    <th scope="col">
                        <div>
                            <span style={{
                                position: 'absolute',
                                top: '8px',
                                left: '0',
                                right: '0'
                            }}>
                                รายได้
                            </span>
                            <span style={{
                                position: 'absolute',
                                bottom: '4px',
                                left: '0',
                                right: '0',
                                fontSize: '10px'
                            }}>(หลังหักส่วนลด)</span>
                        </div>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {props.children}
                </tbody>
            </table>
            <p className="table-remark">
                <strong>Remark:</strong> ในตารางเป็นยอดรวม vat
            </p>
        </>
    )
}

export default DefaultTable