import React, { useState } from "react";
import { Form, Container, Spinner, Alert } from "react-bootstrap";
import Networks from "../../network";
import "./index.scss";
import utils from "../../utils";
import FormValidate from "../../components/FormValidate";

const ConfirmOtpForm = props => {
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  const _confirm = event => {
    event.preventDefault();
    setError("");
    setLoading(true);
    let request = null;
    Networks.verifyOtpWithToken(
      props.user?.lineId,
      otp,
      props.telno,
      props.auth?.access_token
    )
      .then(res => {
        if (res.statusOk) {
          Networks.pushMessages(props.user?.lineId, [
            {
              type: "text",
              text: "เข้าสู่ระบบสำเร็จ",
            },
          ])
            .then(() => {
              utils.closeApp();
            })
            .catch(() => {
              utils.closeApp();
            });
        } else if (res.statusCode === 422) {
          setLoading(false);
          setError("เบอร์นี้ถูกใช้งานแล้ว");
        } else if (res.statusCode === 503) {
          setLoading(false);
          setError("ประเภทผู้ใช้งานไม่สามารถใช้งานได้");
        } else {
          setLoading(false);
          setError("ยืนยันล้มเหลว code: " + res.statusCode);
        }
      })
      .catch(e => {
        setLoading(false);
        setError("เกิดข้อผิดพลาด " + e);
      });
  };

  const _onChangeText = (name, value) => {
    setOtp(value);
  };

  return (
    <div className="page-container">
      <Container>
        <Form className="form-content">
          <h1 className="title-header">เข้าสู่ระบบ</h1>
          {!!error && (
            <Alert
              style={{ fontSize: "0.8rem" }}
              variant={!!error ? "danger" : "primary"}
            >
              {error || "ยืนยันเบอร์โทรศัพท์"}
            </Alert>
          )}
          <FormValidate
            validates={[
              {
                rule: v => v.trim() !== "",
                msg: "Required",
              },
            ]}
            initForm={{ otp: "" }}
            onKeyValueChange={_onChangeText}
            onSubmit={_confirm}
          >
            {({ values, errors, onChangeText, onBlur, onSubmit }) => (
              <>
                <Form.Group>
                  <Form.Label>Ref. {props.refOtp}</Form.Label>
                  <Form.Control
                    className={!!errors.otp ? "form-input-error" : ""}
                    disabled={loading}
                    name="otp"
                    value={values.otp}
                    placeholder="รหัส OTP ที่ได้รับ"
                    onChange={onChangeText}
                    onBlur={onBlur}
                  />
                  {!!errors.otp && (
                    <div className="form-error">{errors.otp}</div>
                  )}
                </Form.Group>
                <button
                  className="btn btn-custom mb-3"
                  disabled={loading}
                  onClick={onSubmit}
                >
                  {loading && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  {" ยืนยัน"}
                </button>
              </>
            )}
          </FormValidate>
        </Form>
      </Container>
    </div>
  );
};

export default ConfirmOtpForm;
